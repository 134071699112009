import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {
	CreateTournamentBody,
	Game, LeaderboardType, PrimaryTieBreakMethod,
	Tournament,
	TournamentApi,
	TournamentOptions,
	TournamentOptionsLookup, TournamentType,
	UserApi
} from "client";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Row
} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import SelectOptionsFactory, {ISelectOption} from "../forms/SelectOptionsFactory";
import NumberFormat from "react-number-format";
import {clone, fill} from "lodash";
import {tieBreakLookup} from "../../utils/TieBreakLookup";
import {leaderboardTypeLookup} from "../../utils/leaderboardTypeLookup";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: Partial<CreateTournamentBody>;
	onTextChange(key: keyof CreateTournamentBody): (e) => void;
	irregularOnChange(key: string, value: any): void;
}

const PrimaryGameForm: React.FC<IProps> = (props: IProps) => {

	const {tournament} = props;
	const [tournamentTypes, setTournamentTypes] = useState<TournamentOptionsLookup>({});

	useEffect(() => {
		getTournamentTypeConfig().then().catch();
	}, []);

	async function getTournamentTypeConfig(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new TournamentApi(getConfig(props.token)).getTournamentOptionsLookup();
			setTournamentTypes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function handleGameTypeChange(key: keyof Game): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			props.irregularOnChange("primaryGame", {...tournament.primaryGame, [key]: e.target.value});
		}
	}

	function filterGameTypes(types: TournamentOptionsLookup): Array<ISelectOption> {
		const ops: Array<ISelectOption> = [];
		for (const type in types) {
			if (types[type]) {
				ops.push({
					value: type,
					label: types[type].name,
				});
			}
		}

		return ops;
	}

	function toggleHandicapOptions(key: keyof Game): () => void {
		return () => {
			let newVal: boolean = true;
			if (tournament.primaryGame[key] === true) {
				newVal = false;
			}
			props.irregularOnChange("primaryGame", {...tournament.primaryGame, [key]: newVal});
		}
	}

	function handicapPercentageHelper(e): void {
		let val: number = e.floatValue;
		if (val > 100) {
			val = 100;
		}

		props.irregularOnChange("primaryGame", {...tournament.primaryGame, applyHandicapPercentage: val});
	}

	function makeTeamHandicapPercentageInputs(): ReactNode {

		return fill(Array(tournament.teamSize), undefined).map((x: any, i: number) => {

			function onTeamHandicapPercentageChangeHelper(e): void {
				let val: number = e.floatValue;
				if (val > 100) {
					val = 100;
				}

				const base: number[] = clone(tournament.primaryGame.teamHandicapPercentages);
				base[i] = val;

				props.irregularOnChange("primaryGame", {
					...tournament.primaryGame,
					teamHandicapPercentages: base,
				});
			}

			return (
				<Col xs={6} sm={4} md={4} lg={3} xl={2} className="mb-3">
					<InputGroup>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>%</InputGroupText>
						</InputGroupAddon>
						<NumberFormat
							value={tournament.primaryGame.teamHandicapPercentages[i]}
							customInput={Input}
							allowLeadingZeros={false}
							allowNegative={false}
							decimalScale={0}
							onValueChange={onTeamHandicapPercentageChangeHelper}
						/>
					</InputGroup>
				</Col>
			);
		});
	}

	function onPlayersCountedChange(e): void {
		props.irregularOnChange("primaryGame", {...tournament.primaryGame, strokePlayPlayersCounted: parseInt(e.target.value)});
	}

	const t: TournamentOptions = tournamentTypes[tournament.primaryGame.tournamentType];

	return (
		<Card>
			<CardHeader>Primary Game</CardHeader>
			<CardBody>
				<div className="mb-3">
					<Label>Game Format</Label>
					<Input type="select" placeholder="Game Format" value={tournament.primaryGame.tournamentType} onChange={handleGameTypeChange("tournamentType")}>
						<option selected disabled value="">Select Game Format</option>
						<hr/>
						<SelectOptionsFactory options={filterGameTypes(tournamentTypes)}/>
					</Input>
				</div>

				{tournament.primaryGame.tournamentType.length > 0 && (
					<div className="mb-3">
						<h5>
							{tournamentTypes[tournament.primaryGame.tournamentType]?.name}
						</h5>
						<p>
							{tournamentTypes[tournament.primaryGame.tournamentType]?.description}
						</p>

						<div className="mb-3">
							<Label>Stableford Scoring System</Label>
							<p>
								<input
									type="checkbox"
									name="primaryGameStableford"
									id="primaryGameStableford"
									checked={tournament.primaryGame.stableford === true}
									onChange={toggleHandicapOptions("stableford")}
								/>
								<label htmlFor="primaryGameStableford">Use Stableford</label>
							</p>
						</div>

						<div className="mb-3">
							<Label>Handicaps</Label>
							<p>
								<input
									type="checkbox"
									name="primaryGameUseHandicaps"
									id="primaryGameUseHandicaps"
									checked={tournament.primaryGame.useHandicap === true}
									onChange={toggleHandicapOptions("useHandicap")}
								/>
								<label htmlFor="primaryGameUseHandicaps">Use Handicaps</label>
							</p>
						</div>

						{tournament.primaryGame.useHandicap === true && (
							<React.Fragment>
								{(t.teamHandicapPercentages || t.applyHandicapPercentage) && (
									<React.Fragment>
										<hr/>

										<Row>
											{/*{t.useSlopeHandicaps && (*/}
											{/*	<Col xs={12} md={6} lg={4} className="mb-3">*/}
											{/*		<Label>Slope Handicaps</Label>*/}
											{/*		<p>*/}
											{/*			<input*/}
											{/*				type="checkbox"*/}
											{/*				name="primaryGameUseSlopeHandicaps"*/}
											{/*				id="primaryGameUseSlopeHandicaps"*/}
											{/*				checked={tournament.primaryGame.useSlopeHandicaps === true}*/}
											{/*				onChange={toggleHandicapOptions("useSlopeHandicaps")}*/}
											{/*			/>*/}
											{/*			<label htmlFor="primaryGameUseSlopeHandicaps">Use Slope Handicaps</label>*/}
											{/*		</p>*/}
											{/*	</Col>*/}
											{/*)}*/}

											{t.teamHandicapPercentages && (
												<Col xs={12} className="mb-3">
													<Label>Team Handicap Percentages</Label>
													{tournament.teamSize === 0 ? (
														<p className="text-muted font-italic">Individual Play is selected; to set team handicap percentages, choose a team size in the "Tournament Information" section at the top of the page.</p>
													) : (
														<Row className="flex-wrap">
															{makeTeamHandicapPercentageInputs()}
														</Row>
													)}
												</Col>
											)}

											{t.applyHandicapPercentage && (
												<Col xs={12} md={6} lg={4} className="mb-3">
													<Label>Apply Handicap Percentage</Label>
													<InputGroup>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>%</InputGroupText>
														</InputGroupAddon>
														<NumberFormat
															value={tournament.primaryGame.applyHandicapPercentage}
															customInput={Input}
															allowLeadingZeros={false}
															allowNegative={false}
															decimalScale={0}
															onValueChange={handicapPercentageHelper}
														/>
													</InputGroup>
												</Col>
											)}
										</Row>
									</React.Fragment>
								)}
							</React.Fragment>
						)}

						{(t?.redVsBluesMode || t?.primaryTieBreakMethod || t?.strokePlayPlayersCounted) && (
							<React.Fragment>
								<hr/>
								<Row className="mb-3">
									{t?.redVsBluesMode && (
										<Col xs={12} md={6} lg={4} className="mb-3">
											<Label>Red vs Blue Mode</Label>
											<p>
												<input
													type="checkbox"
													name="primaryGameUseRedVsBluesModeHandicaps"
													id="primaryGameUseRedVsBluesModeHandicaps"
													checked={tournament?.primaryGame?.redVsBluesMode === true}
													onChange={toggleHandicapOptions("redVsBluesMode")}
												/>
												<label htmlFor="primaryGameUseRedVsBluesModeHandicaps">Use Red vs Blue Mode</label>
											</p>
										</Col>
									)}

									{t.strokePlayPlayersCounted && (
										<Col xs={12} md={6} lg={4} className="mb-3">
											<Label>Stroke Play Players Counted</Label>
											{tournament.teamSize === 0 ? (
												<p className="text-muted font-italic">Individual Play is selected; to set the amount of players counted, choose a team size in the "Tournament Information" section at the top of the page.</p>
											) : (
												<Input type="select" placeholder="Stroke Play Players Counted" value={tournament.primaryGame.strokePlayPlayersCounted} onChange={onPlayersCountedChange}>
													<option selected disabled value="">Players Counted</option>
													<hr/>
													<SelectOptionsFactory options={getPlayersCountedOptions(tournament.teamSize)}/>
												</Input>
											)}
										</Col>
									)}

									{t.primaryTieBreakMethod && (
										<Col xs={12} md={6} lg={4} className="mb-3">
											<Label>Primary Tie-Break Method</Label>
											<Input type="select" placeholder="Primary Tie-Break Method" value={tournament.primaryGame.primaryTieBreakMethod} onChange={handleGameTypeChange("primaryTieBreakMethod")}>
												<option selected disabled value="">Tie-Break Method</option>
												<hr/>
												<SelectOptionsFactory options={Object.values(PrimaryTieBreakMethod).map((v): ISelectOption => {return {value: v, label: tieBreakLookup(v)}})}/>
											</Input>
										</Col>
									)}

                                    <Col xs={12} md={6} lg={4} className="mb-3">
                                        <Label>Default Leaderboard Shown</Label>
                                        <Input type="select" placeholder="Default Leaderboard Shown" value={tournament.primaryGame.defaultLeaderboard} onChange={handleGameTypeChange("defaultLeaderboard")}>
                                            <option selected value="">Default Leaderboard Shown</option>
                                            <hr/>
                                            <SelectOptionsFactory options={Object.values(LeaderboardType).map((v): ISelectOption => {return {value: v, label: leaderboardTypeLookup(v)}})}/>
                                        </Input>
                                    </Col>
								</Row>
							</React.Fragment>
						)}
					</div>
				)}
			</CardBody>
		</Card>
	);
};

function getPlayersCountedOptions(teamSize: number): Array<ISelectOption> {
	const options: number[] = [];

	let i: number;
	for (i = 1; i <= teamSize; i++) {
		options.push(i);
	}

	return options.map((op: number, i: number): ISelectOption => {
		return {
			value: op,
			label: op.toString(),
		}
	});
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PrimaryGameForm);
