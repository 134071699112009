import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import CreateGolfCourseModal from "./CreateGolfCourseModal";
import {CreateTournamentBody, GolfCourse, GolfCourseApi, GolfCourseType, TournamentType} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import GolfCourseList from "./GolfCourseList";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: Partial<CreateTournamentBody>;
	isOpen: boolean;
	onClose(): void;
	onSelect(id: string): void;
}

const BrowseCoursesModal: React.FC<IProps> = (props: IProps) => {

	const {tournament, isOpen} = props;

	// determine golf course type for use in the search api
	let golfCourseType: GolfCourseType = tournament.isMixedGender ? GolfCourseType.NONHANDICAPGENDERED : GolfCourseType.NONHANDICAPNONGENDERED;
	if (tournament.primaryGame && tournament.primaryGame.useHandicap) {
		golfCourseType = tournament.isMixedGender ? GolfCourseType.HANDICAPGENDERED : GolfCourseType.HANDICAPNONGENDERED;
	}

	if (tournament.primaryGame && (tournament.primaryGame.tournamentType === TournamentType.SCRAMBLE) && tournament.primaryGame.useHandicap && tournament.isMixedGender) {
		golfCourseType = GolfCourseType.HANDICAPGENDEREDSCRAMBLE;
	}

	const [searchValue, setSearchValue] = useState("");
	const [showAdd, setShowAdd] = useState(false);
	const [apiResults, setApiResults] = useState<Array<GolfCourse>>(undefined);
	const [includeMixedGenderCourses, setIncludeMixedGenderCourses] = useState(tournament.isMixedGender);
	const [includeHandicapCourses, setIncludeHandicapCourses] = useState(tournament.primaryGame && tournament.primaryGame.useHandicap);
	const [includeHandicapGenderedScramble, setIncludeHandicapGenderedScramble] = useState(golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE);
	const [enableScrambleCheckBox, setEnableScrambleCheckBox] = useState(false);
	const [courseToTemplate, setCourseToTemplate] = useState();
	const [firstLoad, setFirstLoad] = useState(true);

	useEffect(() => {
		setIncludeMixedGenderCourses(tournament.isMixedGender);
	}, [tournament.isMixedGender]);

	useEffect(() => {
		if (tournament.primaryGame && tournament.primaryGame.useHandicap !== undefined) {
			setIncludeHandicapCourses(tournament.primaryGame.useHandicap)
		}
	}, [JSON.stringify(tournament.primaryGame)]);

	useEffect(() => {
		setIncludeMixedGenderCourses(tournament.isMixedGender);
		if (tournament.primaryGame && tournament.primaryGame.useHandicap !== undefined) {
			setIncludeHandicapCourses(tournament.primaryGame.useHandicap)
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			searchCourses().then().catch();
		}
	}, [isOpen]);

	useEffect(() => {
		if (courseToTemplate) {
			setShowAdd(true);
			setCourseToTemplate(undefined);
		}
	}, [courseToTemplate]);

	useEffect(() => {
		if (includeMixedGenderCourses && includeHandicapCourses) {
			setEnableScrambleCheckBox(true);
			if (firstLoad && golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) {
				setIncludeHandicapGenderedScramble(true);
				setFirstLoad(false);
			}
		} else {
			setEnableScrambleCheckBox(false);
			setIncludeHandicapGenderedScramble(false);
		}
	}, [includeMixedGenderCourses, includeHandicapCourses, golfCourseType]);

	function toggleIncludeMixedGenderSearchResults(): void {
		setIncludeMixedGenderCourses(!includeMixedGenderCourses);
	}

	function toggleIncludeHandicapSearchResults(): void {
		setIncludeHandicapCourses(!includeHandicapCourses);
	}

	function toggleIncludeHandicapGenderedScramble(): void {
		setIncludeHandicapGenderedScramble(!includeHandicapGenderedScramble)
	}

	/**
	 * call api with search field to get list
	 *
	 */
	async function searchCourses(e?): Promise<void> {
		if (e) {
			e.preventDefault();
		}

		props.dispatch(incrementLoading());
		try {
			const res = await new GolfCourseApi(getConfig(props.token)).searchGolfCourse({
				searchString: searchValue,
				useHandicap: includeHandicapCourses,
				isMixedGender: includeMixedGenderCourses,
				isScramble: includeHandicapGenderedScramble,
			});
			setApiResults(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
		props.dispatch(decrementLoading());
	}

	function resetAndClose(): void {
		setShowAdd(false);
		setSearchValue("");
		props.onClose();
	}

	function toggleShowAddModal(): void {
		setShowAdd(!showAdd);
	}

	function onSearchValueChange(e): void {
		setSearchValue(e.target.value);
	}

	function handleSuccessfulAdd(id: string): void {
		setShowAdd(false);
		props.onSelect(id);
	}

	function selectTemplate(id: string): void {
		setCourseToTemplate(id);
	}

	return (
		<React.Fragment>
			<CreateGolfCourseModal
				tournament={tournament}
				isOpen={showAdd}
				golfCourseType={golfCourseType}
				onClose={toggleShowAddModal}
				onDone={handleSuccessfulAdd}
				courseToTemplate={courseToTemplate}
			/>

			<Modal
				isOpen={isOpen}
				centered={true}
				fade={true}
				toggle={resetAndClose}
				className="browse-courses-modal"
			>
				<ModalHeader toggle={resetAndClose}>Golf Courses</ModalHeader>

				<ModalBody className="overflow-y-scroll">
					<form onSubmit={searchCourses}>
						<div className="d-flex flex-column flex-sm-row">
							<Input value={searchValue} placeholder="Search by name or location..." onChange={onSearchValueChange} className="mb-3 mb-sm-0"/>
							<Button color="lightBlue" className="px-5 ml-0 ml-sm-3" onClick={searchCourses} type="submit">
								Search
							</Button>
						</div>
					</form>

					<div className="mt-3 mx-2">
						<p className="d-inline mr-5 mb-3">
							<input
								type="checkbox"
								name="includeMixedGenderCoursesSearch"
								id="includeMixedGenderCoursesSearch"
								checked={includeMixedGenderCourses}
								onChange={toggleIncludeMixedGenderSearchResults}
							/>
							<label htmlFor="includeMixedGenderCoursesSearch">Mixed Gender Courses</label>
						</p>

						<p className="d-inline mr-5 mb-3">
							<input
								type="checkbox"
								name="includeHandicapCoursesSearch"
								id="includeHandicapCoursesSearch"
								checked={includeHandicapCourses}
								onChange={toggleIncludeHandicapSearchResults}
							/>
							<label htmlFor="includeHandicapCoursesSearch">Handicapped Courses</label>
						</p>

						{enableScrambleCheckBox && (
							<p className="d-inline mr-5 mb-3">
								<input
									type="checkbox"
									name="includeHandicapGenderedScramble"
									id="includeHandicapGenderedScramble"
									checked={includeHandicapGenderedScramble}
									onChange={toggleIncludeHandicapGenderedScramble}
								/>
								<label htmlFor="includeHandicapGenderedScramble">Scramble Courses</label>
							</p>
						)}
					</div>

					<hr/>

					<GolfCourseList
						courses={apiResults}
						onSelect={props.onSelect}
						selectTemplate={selectTemplate}
					/>

				</ModalBody>

				<ModalFooter>
					<span>
						Didn't find the golf course?
					</span>
					<Button color="darkBlue" onClick={toggleShowAddModal}>
						Add New Golf Course
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(BrowseCoursesModal);
