import React from "react";
import {CreateTournamentBody, Tournament} from "client";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Row
} from "reactstrap";
import NumberFormat from "react-number-format";

interface IProps {
	tournament: Partial<CreateTournamentBody>;

	irregularOnChange(key: string, value: any): void;
}

const ExtraGames: React.FC<IProps> = (props: IProps) => {

	const {tournament} = props;

	function toggleExtraGames(key: keyof CreateTournamentBody): () => void {
		return () => {
			let newVal: boolean = true;
			if (tournament[key] === true) {
				newVal = false;
			}
			props.irregularOnChange(key, newVal);
		}
	}

	function moneyCounterPerHelper(key: "parCountMoneyPer" | "birdieCountMoneyPer" | "eagleCountMoneyPer"): (e) => void {
		return (e) => {
			let val: number = e.floatValue;

			if (val > 99999999) {
				val = 99999999;
			}

			props.irregularOnChange(key, val);
		}
	}

	return (
		<Card>
			<CardHeader>Extra Games</CardHeader>
			<CardBody className="pb-0">
				<Label>Enable or disable extra games</Label>
				<Row>
					<Col xs={12} sm={6} md={4} className="mb-4">
						<p className="d-inline mr-5 mb-3">
							<input
								type="checkbox"
								name="extraGamePar"
								id="extraGamePar"
								checked={tournament.hasParCount === true}
								onChange={toggleExtraGames("hasParCount")}
							/>
							<label htmlFor="extraGamePar">Par</label>
						</p>

						{tournament.hasParCount && (
							<div>
								<Label>Money Per Par</Label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>$</InputGroupText>
									</InputGroupAddon>
									<NumberFormat
										value={tournament.parCountMoneyPer}
										customInput={Input}
										allowLeadingZeros={false}
										allowNegative={false}
										decimalScale={0}
										onValueChange={moneyCounterPerHelper("parCountMoneyPer")}
										thousandSeparator={true}
									/>
								</InputGroup>
							</div>
						)}
					</Col>

					<Col xs={12} sm={6} md={4} className="mb-4">
						<p className="d-inline mr-5 mb-3">
							<input
								type="checkbox"
								name="extraGameBirdie"
								id="extraGameBirdie"
								checked={tournament.hasBirdieCount === true}
								onChange={toggleExtraGames("hasBirdieCount")}
							/>
							<label htmlFor="extraGameBirdie">Birdie</label>
						</p>

						{tournament.hasBirdieCount && (
							<div>
								<Label>Money Per Birdie</Label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>$</InputGroupText>
									</InputGroupAddon>
									<NumberFormat
										value={tournament.birdieCountMoneyPer}
										customInput={Input}
										allowLeadingZeros={false}
										allowNegative={false}
										decimalScale={0}
										onValueChange={moneyCounterPerHelper("birdieCountMoneyPer")}
										thousandSeparator={true}
									/>
								</InputGroup>
							</div>
						)}
					</Col>

					<Col xs={12} sm={6} md={4} className="mb-4">
						<p className="d-inline mr-5 mb-3">
							<input
								type="checkbox"
								name="extraGameEagle"
								id="extraGameEagle"
								checked={tournament.hasEagleCount === true}
								onChange={toggleExtraGames("hasEagleCount")}
							/>
							<label htmlFor="extraGameEagle">Eagle</label>
						</p>

						{tournament.hasEagleCount && (
							<div>
								<Label>Money Per Eagle</Label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText>$</InputGroupText>
									</InputGroupAddon>
									<NumberFormat
										value={tournament.eagleCountMoneyPer}
										customInput={Input}
										allowLeadingZeros={false}
										allowNegative={false}
										decimalScale={0}
										onValueChange={moneyCounterPerHelper("eagleCountMoneyPer")}
										thousandSeparator={true}
									/>
								</InputGroup>
							</div>
						)}
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default ExtraGames;
