import React, {CSSProperties, MouseEventHandler} from "react";
import {GolfCourseType, HoleInfo} from "client";
import {cloneDeep, isNil} from "lodash";
import {Input} from "reactstrap";
import NumberFormat from "react-number-format";
import classNames from "classnames";


/**
 * Golf Course Input
 */
export type HolesMap = { [key: number]: HoleInfo };

interface IProps {
	golfCourseType: GolfCourseType;
	holes: HolesMap;
	editPar: boolean;
	showPar: boolean;
	editHandicap: boolean;
	showHandicap: boolean;
	onEditHoles?: (v: HolesMap) => void;
	selectable: boolean;
	style?: CSSProperties;
}

export const defaultHoleNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

const GolfCourseInput: React.FC<IProps> = (props) => {

	const hasOnChange = !isNil(props.onEditHoles);

	function createColumn(holeNumber: number, index: number) {
		const holeInfo = props.holes[holeNumber];
		const holeExists = !isNil(holeInfo);
		const parExists = holeExists && !isNil(holeInfo.par);
		const maleParExists = holeExists && !isNil(holeInfo.malePar);
		const femaleParExists = holeExists && !isNil(holeInfo.femalePar);
		const handicapExists = holeExists && !isNil(holeInfo.handicap);
		const maleHandicapExists = holeExists && !isNil(holeInfo.maleHandicap);
		const femaleHandicapExists = holeExists && !isNil(holeInfo.femaleHandicap);

		const base: number = 20 + holeNumber;
		const parTabIndex: number = base;
		const maleParTabIndex: number = base + 18;
		const femaleParTabIndex: number = base + 36;
		const handicapTabIndex: number = base + 54;
		const maleHandicapTabIndex: number = base + 72;
		const femaleHandicapTabIndex: number = base + 90;

		function onButtonPress() {
			const copy = cloneDeep(props.holes);
			copy[holeNumber].selected = !copy[holeNumber].selected;
			props.onEditHoles(copy);
		}

		function onParChange(par: number) {
			const copy = cloneDeep(props.holes);
			copy[holeNumber].par = par;
			props.onEditHoles(copy);
		}

		function onGenderedParChange(male: boolean): (par: number) => void {
			return (par: number) => {
				const copy = cloneDeep(props.holes);
				copy[holeNumber][male ? "malePar" : "femalePar"] = par;
				props.onEditHoles(copy);
			}
		}

		function onHandicapChange(handicap: number) {
			const copy = cloneDeep(props.holes);
			copy[holeNumber].handicap = handicap <= 18 ? handicap : (handicap === undefined ? "" as unknown as number : 18);
			props.onEditHoles(copy);
		}

		function onGenderedHandicapChange(male: boolean): (handicap: number) => void {
			return (handicap: number) => {
				const copy = cloneDeep(props.holes);
				copy[holeNumber][male ? "maleHandicap" : "femaleHandicap"] = handicap;
				props.onEditHoles(copy);
			}
		}

		return (
			<div
				className="d-flex flex-column"
				key={"hole_" + index}
			>
				<GolfCourseButton
					label={holeNumber.toString()}
					selected={holeExists && holeInfo.selected}
					onClick={(props.selectable && holeExists && hasOnChange) ? onButtonPress : undefined}
				/>

				{props.showPar && (
					<>
						{(props.golfCourseType === GolfCourseType.NONHANDICAPGENDERED || props.golfCourseType === GolfCourseType.HANDICAPGENDERED) ? (
							<>
								<GolfCourseField
									value={maleParExists ? holeInfo.malePar : undefined}
									onChange={(props.editPar && holeExists && hasOnChange) ? onGenderedParChange(true) : undefined}
									parTabIndex={maleParTabIndex}
								/>
								<GolfCourseField
									value={femaleParExists ? holeInfo.femalePar : undefined}
									onChange={(props.editPar && holeExists && hasOnChange) ? onGenderedParChange(false) : undefined}
									parTabIndex={femaleParTabIndex}
								/>
							</>
						) : (
							<GolfCourseField
								value={parExists ? holeInfo.par : undefined}
								onChange={(props.editPar && holeExists && hasOnChange) ? onParChange : undefined}
								parTabIndex={parTabIndex}
							/>
						)}
					</>
				)}

				{props.showPar && props.showHandicap && <hr className="golf-course-hr"/>}

				{props.showHandicap && (
					<>
						{props.golfCourseType === GolfCourseType.HANDICAPGENDERED ? (
							<>
								<GolfCourseField
									value={maleHandicapExists ? holeInfo.maleHandicap : undefined}
									onChange={(props.editHandicap && holeExists && hasOnChange) ? onGenderedHandicapChange(true) : undefined}
									parTabIndex={maleHandicapTabIndex}
								/>
								<GolfCourseField
									value={femaleHandicapExists ? holeInfo.femaleHandicap : undefined}
									onChange={(props.editHandicap && holeExists && hasOnChange) ? onGenderedHandicapChange(false) : undefined}
									parTabIndex={femaleHandicapTabIndex}
								/>
							</>
						) : ((props.golfCourseType === GolfCourseType.HANDICAPNONGENDERED || props.golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) ? (
							<GolfCourseField
								value={handicapExists ? holeInfo.handicap : undefined}
								onChange={(props.editHandicap && holeExists && hasOnChange) ? onHandicapChange : undefined}
								parTabIndex={handicapTabIndex}
							/>
						) : null)}
					</>
				)}
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex" style={props.style}>
				{(props.showPar || props.showHandicap) && (
					<div className="d-flex flex-column" style={{minWidth: 130, whiteSpace: "nowrap"}}>

						<div className="golf-course-field-wrapper"/>

						{props.showPar && (
							<>
								{(props.golfCourseType === GolfCourseType.NONHANDICAPGENDERED || props.golfCourseType === GolfCourseType.HANDICAPGENDERED) ? (
									<>
										<div className="golf-course-label-wrapper">
											<p className="golf-course-label">Male Par</p>
										</div>
										<div className="golf-course-label-wrapper">
											<p className="golf-course-label">Female Par</p>
										</div>
									</>
								) : (
									<div className="golf-course-label-wrapper">
										<p className="golf-course-label">Par</p>
									</div>
								)}
							</>
						)}

						{props.showHandicap && (
							<>
								{props.golfCourseType === GolfCourseType.HANDICAPGENDERED ? (
									<>
										<div className="golf-course-label-wrapper"
										     style={{marginTop: props.showPar ? "2px" : undefined}}>
											<p className="golf-course-label">Male Handicap</p>
										</div>
										<div className="golf-course-label-wrapper"
										     style={{marginTop: props.showPar ? "2px" : undefined}}>
											<p className="golf-course-label">Female Handicap</p>
										</div>
									</>
								) : ((props.golfCourseType === GolfCourseType.HANDICAPNONGENDERED || props.golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) ? (
									<div className="golf-course-label-wrapper"
									     style={{marginTop: props.showPar ? "2px" : undefined}}>
										<p className="golf-course-label">Handicap</p>
									</div>
								) : null)}
							</>
						)}
					</div>
				)}
				<div className="d-flex flex-column">
					<div className="d-flex flex-row">
						{defaultHoleNumbers.map(createColumn)}
					</div>

					{props.selectable && (
						<>
							<p className="mt-2 mb-1">
								Click on a hole number above to toggle it.
							</p>

							<div className="d-flex">
								<div className="mr-4 d-flex align-items-center">
									<div className="golf-course-input-active-legend bg-darkBlue"/>
									<span className="text-secondary">Active</span>
								</div>

								<div className="d-flex align-items-center">
									<div className="golf-course-input-active-legend bg-gray"/>
									<span className="text-secondary">Inactive</span>
								</div>
							</div>
						</>
					)}
				</div>
			</div>

		</div>
	);
};


/**
 * Golf Course Button
 */
interface IGolfCourseButtonProps {
	label: string;
	onClick?: MouseEventHandler<any>;
	selected: boolean;
}

export const GolfCourseButton: React.FC<IGolfCourseButtonProps> = (props) => {

	const hasOnClick = !isNil(props.onClick);

	return (
		<div
			className={classNames("golf-course-button", props.selected ? "bg-darkBlue border-darkBlue text-white" : "bg-gray border-gray text-secondary", {"cursor-pointer": hasOnClick})}
			onClick={props.onClick}
		>
			<p className="golf-course-button-text">
				{props.label}
			</p>
		</div>
	);
};

/**
 * Golf Course Field
 */
interface IGolfCourseField {
	value?: number;
	onChange?: (v: number) => void;
	parTabIndex: number;
}

export const GolfCourseField: React.FC<IGolfCourseField> = (props) => {

	const editable = !isNil(props.onChange);
	const hasValue = !isNil(props.value);

	// const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
	// 	const value = Number(e.target.value);
	// 	if (isNaN(value)) {
	// 		return;
	// 	}
	// 	props.onChange(value)
	// }

	function onChange(e): void {
		if (e.floatValue > 99) {
			props.onChange(99);
		} else {
			props.onChange(e.floatValue);
		}
	}

	return (
		<div className="golf-course-field-wrapper">
			{
				editable ?
					<>
						<NumberFormat
							className="m-1 p-1 text-center"
							value={props.value}
							customInput={Input}
							allowLeadingZeros={false}
							allowNegative={false}
							decimalScale={0}
							onValueChange={onChange}
							maxLength={2}
							tabIndex={props.parTabIndex}
						/>
					</> :
					<p className="golf-course-field-text">{hasValue ? props.value : "-"}</p>
			}
		</div>
	)
}


export default GolfCourseInput;
