import React, {useState} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import SideBarManager from "./components/sideBar/SideBarManager";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Register from "./pages/Register";
import CreateTournament from "./pages/CreateTournament";
import GolfCourseInput from "./components/golfCourse/GolfCourseInput";
import TournamentDashboard from "./pages/TournamentDashboard";
import TournamentPlayers from "./pages/TournamentPlayers";
import TournamentPairing from "./pages/TournamentPairing";
import TournamentDetails from "./pages/TournamentDetails";
import TournamentAssets from "./pages/TournamentAssets";
import TournamentPrintOuts from "./pages/TournamentPrintOuts";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						<Route exact path="/" component={Login}/>
						<Route exact path="/register" component={Register}/>
						<Route exact path="/debug" component={() => <Debug/>}/>

						<AuthenticatedRoute exact path="/iscoregolfplus" component={Dashboard}/>
						<AuthenticatedRoute exact path="/create-tournament" component={CreateTournament}/>
						<AuthenticatedRoute exact path="/existing-tournament/dashboard" component={TournamentDashboard}/>
						<AuthenticatedRoute exact path="/existing-tournament/players" component={TournamentPlayers}/>
						<AuthenticatedRoute exact path="/existing-tournament/pairing" component={TournamentPairing}/>
						<AuthenticatedRoute exact path="/existing-tournament/details" component={TournamentDetails}/>
						<AuthenticatedRoute exact path="/existing-tournament/assets" component={TournamentAssets}/>
						<AuthenticatedRoute exact path="/existing-tournament/print-outs" component={TournamentPrintOuts}/>
						<Redirect from="/existing-tournament" to="/iscoregolfplus"/>

						<Route exact path="/404" component={NotFoundPage}/>
						<Redirect to="/404"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

const holeInfoDefault = {}
for (let i = 1; i <= 18; i++) {
	holeInfoDefault[i] = {selected: true};
}

const Debug = () => {

	const [holeInfo, updateHoleInfo] = useState(holeInfoDefault);

	return null;
};

export default App;
