import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {TournamentApi} from "client";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	token?: string;
	isOpen: boolean;
	tournamentID: string;
	onClose(): void;
	onDone(): Promise<void>;
}

const ConfirmResetTournamentScoresModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, tournamentID} = props;
	const [showSecondConfirmation, setShowSecondConfirmation] = useState(false);

	function toggleShowSecondConfirmation(): void {
		setShowSecondConfirmation(!showSecondConfirmation);
	}

	async function confirmReset(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).deleteScoresForTournament({
				iDBody: {
					id: tournamentID,
				},
			});

			setShowSecondConfirmation(false);
			props.onClose();
			props.onDone().then().catch();

		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showSecondConfirmation}
				centered={true}
			>
				<ModalHeader>Really Reset Tournament Scores?</ModalHeader>

				<ModalBody>
					Are you <b>absolutely</b> sure you want to <b>reset</b> the <b>scores</b> that have been entered for this tournament?
				</ModalBody>

				<ModalFooter>
					<Button color="gray" onClick={toggleShowSecondConfirmation}>
						Don't Reset
					</Button>

					<Button color="danger" onClick={confirmReset}>
						Confirm Reset
					</Button>
				</ModalFooter>
			</Modal>


			<Modal
				isOpen={isOpen}
				centered={true}
			>
				<ModalHeader>Reset Tournament Scores</ModalHeader>

				<ModalBody>
					Are you sure you want to reset this tournament's entered scores? This action cannot be un-done.
				</ModalBody>

				<ModalFooter>
					<Button color="gray" onClick={props.onClose}>
						Cancel
					</Button>

					<Button color="yellow" className="text-white" onClick={toggleShowSecondConfirmation}>
						Yes, Reset Scores
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ConfirmResetTournamentScoresModal)
