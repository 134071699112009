import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {Button, Card, CardBody, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {UserApi, SignUpBody} from "client";
import getConfig from "../utils/getConfig";
import {NavLink} from "react-router-dom";
import RequiredAsterisk from "../components/forms/RequiredAsterisk";

interface IProps {
	dispatch?: any;
}

const defaultRegisterForm: SignUpBody = {
	email: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	phoneNumber: "",
};

const Register: React.FC<IProps> = (props: IProps) => {

	const [form, setForm] = useState<SignUpBody>(defaultRegisterForm);
	const [showModal, setShowModal] = useState(false);

	function createOnChange(key: keyof SignUpBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * submit sign up form and show confirmation modal on success
	 *
	 * @param e
	 */
	async function submitRegistration(e): Promise<void> {
		if (e) {
			e.preventDefault()
		}

		props.dispatch(incrementLoading());

		try {
			await new UserApi(getConfig()).signUp({signUpBody: form});

			props.dispatch(decrementLoading());
			setShowModal(true);
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showModal}
				fade={true}
				centered={true}
			>
				<ModalHeader>Registration Submitted</ModalHeader>

				<ModalBody>
					<h4>
						Thank you
					</h4>
					<p>
						Your registration request has been successfully submitted and will be reviewed by our admins for approval. Once you are approved you will be notified by email, and then have full access to the software. In the meantime, you may login with limited privileges.
					</p>
				</ModalBody>

				<ModalFooter>
					<NavLink to="/">
						<Button color="success">
							Dismiss
						</Button>
					</NavLink>
				</ModalFooter>
			</Modal>

			<div className="vh-100 vw-100 overflow-y-scroll">
				<div className="w-100 h-100 login-background position-absolute"/>
				<Container className="vh-100 d-flex justify-content-center">
					<div className="login-form-card">
						<div className="login-form-card_mobile-spacer"/>

						<Card className="w-100">
						<CardBody>
							<h4 className="mb-3 text-center">
								iScoreGolf Plus Tournament Organizer Registration
							</h4>

							<form>
								<div className="mb-3">
									<Label>First Name<RequiredAsterisk/></Label>
									<Input placeholder="First Name" value={form.firstName}
									       onChange={createOnChange("firstName")}/>
								</div>

								<div className="mb-3">
									<Label>Last Name<RequiredAsterisk/></Label>
									<Input placeholder="Last Name" value={form.lastName}
									       onChange={createOnChange("lastName")}/>
								</div>

								<div className="mb-3">
									<Label>Phone Number<RequiredAsterisk/></Label>
									<Input type="tel" placeholder="Phone Number" value={form.phoneNumber}
									       onChange={createOnChange("phoneNumber")}/>
								</div>

								<div className="mb-3">
									<Label>Email<RequiredAsterisk/></Label>
									<Input type="email" placeholder="Email Address" value={form.email}
									       onChange={createOnChange("email")}/>
								</div>

								<div className="mb-3">
									<Label>Password<RequiredAsterisk/></Label>
									<Input type="password" placeholder="Password" value={form.password}
									       onChange={createOnChange("password")}/>
								</div>

								<div className="mb-3">
									<Label>Confirm Password<RequiredAsterisk/></Label>
									<Input type="password" placeholder="Re-enter Password" value={form.confirmPassword}
									       onChange={createOnChange("confirmPassword")}/>
								</div>

								<div className="d-flex justify-content-end">
									<Button color="darkBlue" type="submit" onClick={submitRegistration}>
										Register
									</Button>
								</div>

								<p className="mb-0 mt-3 text-center">
									Already have an account? <NavLink to="/" className="text-lightBlue">Log in</NavLink>
								</p>
							</form>
						</CardBody>
					</Card>

						<div className="login-form-card_mobile-spacer"/>
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default connect()(Register);
