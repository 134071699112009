import React, {useEffect, useRef, useState} from "react";
import {Card} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {GetTournamentLogsResponse, TournamentApi} from "client";
import getConfig from "../../utils/getConfig";
import { connect } from "react-redux";
import {IStore} from "../../redux/defaultStore";
import moment from "moment";
import classNames from "classnames";

interface IProps {
	token?: string;
	dispatch?: any;
	tournamentID: string;
	forceChildrenToUpdateKey: number;
}

const TournamentLogs: React.FC<IProps> = (props: IProps) => {

	const [logs, setLogs] = useState<GetTournamentLogsResponse>();
	const scrollRef: any = useRef();

	useEffect(() => {
		readTournamentLogs().then().catch();
	}, [props.forceChildrenToUpdateKey]);

	useEffect(() => {
		if (scrollRef && scrollRef.current) {
			scrollRef.current.scrollIntoView();
		}
	}, [JSON.stringify(logs)]);

	async function readTournamentLogs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).getTournamentLogs({tournamentID: props.tournamentID});
			setLogs(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Card className="h-100 overflow-y-scroll">
			{(logs && Object.keys(logs).length > 0 && logs.logs.length > 0) ? (
				<div>
					{logs.logs.reverse().map((_log, i) => {
						return (
							<div
								className={classNames("px-4 py-3", i % 2 ? "bg-gray border-gray" : "white border-white")}
								style={{
									borderStyle: "solid",
									borderTopWidth: 1,
									borderBottomWidth: 1,
									borderLeftWidth: 0,
									borderRightWidth: 0,
								}}
							>
								<label className="mb-1">
									{moment(_log.time).format("MMM d - h:mm A")}
								</label>
								<p className="mb-0" style={{fontSize: "0.9rem"}}>
									{_log.description}
								</p>
							</div>
						)
					})}
					<div ref={scrollRef}/>
				</div>
			) : (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					No tournament logs yet.
				</div>
			)}
		</Card>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentLogs);
