import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Player, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import {useQuery} from "../utils/useQuery";
import PlayerPairingsTable from "../components/tables/PlayerPairingsTable";
import SMSURLsToAllPlayersConfirmModal from "../components/modals/SMSURLsToAllPlayersConfirmModal";
import EmailURLsToAllPlayersConfirmModal from "../components/modals/EmailURLsToAllPlayersConfirmModal";
import {useHistory} from "react-router";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TournamentPairing: React.FC<IProps> = (props: IProps) => {

	const {token} = props;
	const history = useHistory();
	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const [data, setData] = useState<Array<Player>>(undefined);
	const [showEmailToAllModal, setShowEmailToAllModal] = useState(false);
	const [showSMSToAllModal, setShowSMSToAllModal] = useState(false);

	useEffect(() => {
		getTournamentPairings().then().catch();
	}, [tournamentID]);

	if (!tournamentID) {
		history.replace("/iscoregolfplus");
		return null;
	}

	async function getTournamentPairings(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new TournamentApi(getConfig(token)).getPairings({tournamentID});
			setData(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function toggleEmailToAllModal(): void {
		setShowEmailToAllModal(!showEmailToAllModal);
	}

	function toggleSMSToAllModal(): void {
		setShowSMSToAllModal(!showSMSToAllModal);
	}

	return (
		<React.Fragment>
			<EmailURLsToAllPlayersConfirmModal
				isOpen={showEmailToAllModal}
				tournamentId={tournamentID}
				onClose={toggleEmailToAllModal}
				onDone={getTournamentPairings}
			/>

			<SMSURLsToAllPlayersConfirmModal
				isOpen={showSMSToAllModal}
				tournamentId={tournamentID}
				onClose={toggleSMSToAllModal}
				onDone={getTournamentPairings}
			/>

			<div className="px-3">
				<Container className="my-5">
					<h1>
						Tournament Pairing
					</h1>

					<div className="d-flex flex-column flex-lg-row my-3">
						<Button color="darkBlue" className="m-2" onClick={toggleEmailToAllModal}>
							Email links to all players
						</Button>

						<Button color="darkBlue" className="m-2" onClick={toggleSMSToAllModal}>
							SMS links to all players
						</Button>
					</div>
				</Container>

				<PlayerPairingsTable
					data={data}
					onDone={getTournamentPairings}
				/>
			</div>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentPairing);
