import React, {useState} from "react";
import {Player, PlayerGroupings, TeamWithPlayers, TournamentApi} from "client";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {
	AiOutlineMail,
	BiMailSend,
	FiEdit,
	FiEdit2,
	FiEdit3,
	FiMessageCircle,
	FiX,
	MdPermPhoneMsg
} from "react-icons/all";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AddPlayerModal from "./AddPlayerModal";
import classNames from "classnames";
import EditPlayerConfirmSendTextModal from "../modals/EditPlayerConfirmSendTextModal";

interface IProps {
	token?: string;
	dispatch?: any;
	player: Player;
	tournamentID: string;
	teams: Array<TeamWithPlayers>;
	playerGroupings: PlayerGroupings;
	isLast: boolean;
	getPlayerGroupings(): Promise<void>;
}

const EditPlayerCell: React.FC<IProps> = (props: IProps) => {

	const {token, player, tournamentID, teams, isLast} = props;
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeletePlayerConfirmation, setShowDeletePlayerConfirmation] = useState(false);
	const [showSendTextModal, setShowSendTextModal] = useState(false);

	function toggleShowEditModal(): void {
		setShowEditModal(!showEditModal);
	}

	function toggleShowDeletePlayerConfirmation(): void {
		setShowDeletePlayerConfirmation(!showDeletePlayerConfirmation);
	}

	function toggleSendTextModal(): void {
		setShowSendTextModal(s => !s);
	}

	async function confirmDeletePlayer(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			await new TournamentApi(getConfig(token)).deletePlayer({iDBody: {id: player._id}});
			setShowDeletePlayerConfirmation(false);
			props.dispatch(decrementLoading());
			props.getPlayerGroupings().then().catch();
		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	async function finishEditingPlayer(): Promise<void> {
		setShowEditModal(false);
		await props.getPlayerGroupings();
	}

	async function finishSendingURL(): Promise<void> {
		setShowSendTextModal(false);
		await props.getPlayerGroupings();
	}

	return (
		<React.Fragment>
			<EditPlayerConfirmSendTextModal
				isOpen={showSendTextModal}
				player={props.player}
				onClose={toggleSendTextModal}
				onDone={finishSendingURL}
			/>

			<Modal
				isOpen={showDeletePlayerConfirmation}
				centered={true}
			>
				<ModalHeader toggle={toggleShowDeletePlayerConfirmation}>Confirm Deletion of Player</ModalHeader>
				<ModalBody>
					<p>
						{`Are you sure you want to delete ${player.firstName} ${player.lastName}?`}
						<br/>
						<br/>
						To un-assign this player from the team without deleting them, use the edit icon and set their team to "Unassigned".
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color="gray" className="mr-3" onClick={toggleShowDeletePlayerConfirmation}>
						Cancel
					</Button>
					<Button color="yellow" className="text-white" onClick={confirmDeletePlayer}>
						Yes, Delete
					</Button>
				</ModalFooter>
			</Modal>

			<AddPlayerModal
				isOpen={showEditModal}
				tournamentID={tournamentID}
				teams={teams}
				player={player}
				playerGroupings={props.playerGroupings}
				onClose={toggleShowEditModal}
				onDone={finishEditingPlayer}
			/>

			<div className={classNames("pl-4 pr-3 py-2", isLast ? "" : "border-bottom border-gray")} style={{minHeight: isLast ? 72 : 71, borderWidth: isLast ? 0 : 1}}>
				<div className="d-flex flex-row justify-content-between mb-2">
					<div className="pr-1 player-team-card-name d-flex align-items-center">
						<h6 className="text-ellipsis mb-0">{`${player.firstName} ${player.lastName}`}</h6>
					</div>

					<div className="px-1 d-flex justify-content-end">
						<a
							className={classNames("ml-2 text-darkBlue edit-player-cell_action-icon", {
								"edit-player-cell_action-icon_enabled": (player.phoneNumber?.length > 0 && player.lastDateMessageWasSent === undefined) || (player.email?.length > 0 && player.lastDateEmailWasSent === undefined)
							})}
							href="#"
							onClick={toggleSendTextModal}
						>
							<AiOutlineMail size="1.2rem" style={{maxHeight: 20}}/>
						</a>

						<a className="ml-2 text-darkBlue" href="#" onClick={toggleShowEditModal}>
							<FiEdit2 size="1.2rem" style={{maxHeight: 20}}/>
						</a>

						<a className="ml-2 text-yellow" href="#" onClick={toggleShowDeletePlayerConfirmation}>
							<FiX size="1.2rem" style={{maxHeight: 20}}/>
						</a>
					</div>
				</div>

				<div className="d-flex justify-content-between">
					<span className="text-dark font-weight-light">
						{player.male !== undefined ? (player.male ? "Male" : "Female") : ""}
					</span>

					<span className="text-right text-dark font-weight-light">
						{(player.handicap !== undefined) ? ("Handicap: " + player.handicap) : (player.handicapIndex ? ("Handicap Index: " + player.handicapIndex) : "")}
					</span>
				</div>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EditPlayerCell);
