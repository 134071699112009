import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Tournament} from "client";
import {Collapse} from "reactstrap";
import {FiChevronDown, FiChevronRight} from "react-icons/all";
import {NavLink, useLocation} from "react-router-dom";
import {useQuery} from "../../utils/useQuery";
import {updateTournamentList} from "../../redux/tournamentList/TournamentListActions";
import classNames from "classnames";

interface IProps {
	token?: string;
	dispatch?: any;
	tournaments?: Array<Tournament>;
}

const TournamentList: React.FC<IProps> = (props: IProps) => {

	const location = useLocation();

	useEffect(() => {
		if (props.token) {
			props.dispatch(updateTournamentList(props.token));
		}
	}, [props.token]);

	const query = useQuery();
	const [openList, setOpenList] = useState(-1);

	function determineActive(pathname: string, id: string): (match, location) => boolean {
		return (match, location) => {
			return location.pathname === pathname && query.get("tournamentID") == id;
		}
	}

	function mapTournaments(_tournaments: Array<Tournament> = []): ReactNode {
		if (!_tournaments || _tournaments.length < 1) {
			return (
				<p className="text-muted font-italic text-center">
					You currently have no active or upcoming tournaments. Visit the "Create Tournament" page to make one.
				</p>
			);
		}

		return _tournaments.map((_tournament: Tournament, i: number) => {

			function toggleOpen(e): void {
				e.preventDefault();
				if (openList === i) {
					setOpenList(-1);
				} else {
					setOpenList(i);
				}
			}

			const ToggleIcon = openList === i ? FiChevronDown : FiChevronRight;

			return (
				<div key={`tournament-list-item-${i}`}>
					<a className={"nav-link cursor-pointer" + (query.get("tournamentID") === _tournament._id ? " text-light" : "")} href="#" onClick={toggleOpen}>
						<ToggleIcon size="1.5rem" style={{maxHeight: 20}} className="mr-1"/>
						{_tournament.name}
					</a>

					<Collapse isOpen={openList === i}>
						<div className="side-bar-tournament-list">
						<ul className="text-white">
							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/dashboard?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/dashboard", _tournament._id)}
								>
									Dashboard
								</NavLink>
							</li>
							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/players?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/players", _tournament._id)}
								>
									Players
								</NavLink>
							</li>
							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/pairing?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/pairing", _tournament._id)}
								>
									Pairing
								</NavLink>
							</li>
							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/details?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/details", _tournament._id)}
								>
									Edit Details
								</NavLink>
							</li>
							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/assets?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/assets", _tournament._id)}
								>
									Assets
								</NavLink>
							</li>

							<li>
								<NavLink
									className="text-decoration-none nav-link"
									to={`/existing-tournament/print-outs?tournamentID=${_tournament._id}`}
									isActive={determineActive("/existing-tournament/print-outs", _tournament._id)}
								>
									Print Outs
								</NavLink>
							</li>
						</ul>
						</div>
					</Collapse>
				</div>
			);
		});
	}

	return (
		<div>
			<h5 className="text-light" style={{fontSize: "1.1rem"}}>Your Tournaments</h5>
			{props.tournaments && props.tournaments.length > 0 && (
				<p className="text-light">(Click names for options)</p>
			)}
			{mapTournaments(props.tournaments)}
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		tournaments: store.tournamentListStore.tournaments,
		...props,
	}
})(TournamentList);
