import React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, CardHeader, Container} from "reactstrap";
import {NavLink} from "react-router-dom";

interface IProps {
	token?: string;
	dispatch?: any;
}

const Dashboard: React.FC<IProps> = (props: IProps) => {

	return (
		<Container className="my-5">
			<Card>
				<CardHeader>Introduction</CardHeader>
				<CardBody>
					<h3>iScoreGolfPlus was designed for Corporate or Charity Golf Events</h3>
					<hr/>
					<p>
						The purpose of this platform is to bring technology of real time golfing scoring, sponsorship recognition, social engagement and silent auction capabilities.
						<br/><br/>
						The software was designed with the intent to make it easy to use for the golfer and easy to set-up for a tournament organizer.
						<br/><br/>
						We are here to assist with any questions or issue that you have -- please contact us at <a href="mailto:troy@iscoregolf.ca?subject=iScore Golf Plus Tournament Organizer Portal" target="_blank" rel="noreferrer noopener">troy@iscoregolf.ca</a> for more information.
					</p>
					<hr/>

					<div className="d-flex justify-content-center">
						<NavLink to="/create-tournament">
							<Button color="lightBlue">Create a Tournament</Button>
						</NavLink>
					</div>
				</CardBody>
			</Card>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(Dashboard);
