import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {TournamentApi} from "client";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";

interface IProps {
	dispatch?: any;
	token?: string;
	isOpen: boolean;
	tournamentID: string;
	onClose(): void;
	onDone(): Promise<void>;
}

const UploadPlayersFromCSVModal: React.FC<IProps> = (props: IProps) => {

	const inputRef = useRef<HTMLInputElement>();
	const {isOpen, tournamentID} = props;
	const [file, setFile] = useState(null);

	function resetAndClose(): void {
		setFile(null);
		props.onClose()
	}

	function onFileChange(e): void {
		if (e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0])
		} else {
			setFile(null);
		}
	}

	async function submitCSV(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).uploadCSVPlayerData({
				tournamentID,
				csv: file,
			});
			resetAndClose();
			props.onDone().then().catch();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function inputClick(): void {
		inputRef?.current?.click();
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
		>
			<ModalHeader>Upload Players from CSV</ModalHeader>

			<ModalBody>
				{isOpen && (
					<>
						<Button color="darkBlue" className="mr-3 mb-2" onClick={inputClick}>Choose File</Button>
						<span>{file ? file["name"] : "No file chosen"}</span>

						<input
							type="file"
							id="uploadPlayersFromCSVInput"
							onChange={onFileChange}
							ref={inputRef}
							className="d-none"
						/>
					</>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color="gray" onClick={resetAndClose}>
					Cancel
				</Button>

				<Button color="darkBlue" className="ml-3" onClick={submitCSV} disabled={file === null}>
					Upload
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
	}
})(UploadPlayersFromCSVModal);
