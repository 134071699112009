import React, {ReactNode} from "react";
import {GolfCourse, GolfCourseType} from "client";
import {Button, Card, CardBody, Col, Label, Row, Spinner} from "reactstrap";
import GolfCourseInput from "./GolfCourseInput";
import {isEqual} from "lodash";

interface IProps {
	courses: Array<GolfCourse>;
	onSelect(id: any);
	selectTemplate(id: any);
}

const GolfCourseList: React.FC<IProps> = (props: IProps) => {

	function makeDisplayColumn(label: string, value: any): ReactNode {
		return (
			<Col xs={12} sm={6} md={3} className="mb-3">
				<Label>{label}</Label>
				<p className="h4">{value ? value : "-"}</p>
			</Col>
		);
	}

	function mapCourses(_courses: Array<GolfCourse> ): ReactNode {
		if (!_courses) {
			return (
				<div className="d-flex justify-content-center align-items-center w-100 h-100">
					<div className="d-flex align-items-center">
						<Spinner size="sm" color="secondary"/>
						<span className="ml-2 text-secondary">
							Loading...
						</span>
					</div>
				</div>
			);
		}

		return _courses.map((_course: GolfCourse, i: number) => {

			function select(): void {
				props.onSelect(_course._id);
			}

			function template(): void {
				props.selectTemplate(_course._id);
			}

			return (
				<Card key={`golf-course-result-${i}`} className="mb-3">
					<CardBody>
						<Row>
							{makeDisplayColumn("Golf Club", _course.golfClub)}
							{makeDisplayColumn("Address", _course.address)}
							{makeDisplayColumn("Country", _course.country)}
							{makeDisplayColumn("Province / State", _course.province)}
							{(_course.golfCourseType === GolfCourseType.HANDICAPGENDERED || _course.golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) ? (
								<>
									{makeDisplayColumn("Male Course Rating", _course.maleCourseRating)}
									{makeDisplayColumn("Female Course Rating", _course.femaleCourseRating)}
									{makeDisplayColumn("Male Slope Rating", _course.maleSlopeRating)}
									{makeDisplayColumn("Female Slope Rating", _course.femaleSlopeRating)}
								</>
							) : ((_course.golfCourseType === GolfCourseType.HANDICAPNONGENDERED) ? (
								<>
									{makeDisplayColumn("Course Rating", _course.courseRating)}
									{makeDisplayColumn("Slope Rating", _course.slopeRating)}
								</>
							) : null)}
						</Row>
					</CardBody>

					<CardBody className="overflow-x-scroll p-0 mr-2">
						<GolfCourseInput
							golfCourseType={_course.golfCourseType}
							holes={_course.holes}
							editPar={false}
							showPar={true}
							editHandicap={false}
							showHandicap={true}
							selectable={false}
						/>
					</CardBody>

					<CardBody className="d-flex justify-content-center">
						<Button color="darkBlue" className="mr-3" onClick={select}>
							Select this course
						</Button>
						<Button color="lightBlue" className="mr-3" onClick={template}>
							Use as template
						</Button>
					</CardBody>
				</Card>
			);
		});
	}

	return (
		<div>
			{mapCourses(props.courses)}
		</div>
	);
};

function areEqual(prevProps, nextProps) {
	/*
	return true if passing nextProps to render would return
	the same result as passing prevProps to render,
	otherwise return false
	*/

	return isEqual(prevProps, nextProps);
}

export default React.memo(GolfCourseList, areEqual);
