import React, {ReactNode} from "react";
import {ChatApi, ChatMessageResponseElement} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	messages: Array<ChatMessageResponseElement>;
	onDelete(): Promise<void>;
}

const MessageList: React.FC<IProps> = (props: IProps) => {

	async function deleteMessage(_message: ChatMessageResponseElement): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new ChatApi(getConfig(props.token)).userDeleteMessage({
				iDBody: {
					id: _message.chatMessage._id,
				},
			});

			props.dispatch(decrementLoading());
			await props.onDelete();
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}
	}

	function mapMessages(_messages: Array<ChatMessageResponseElement> = []): ReactNode {
		return _messages.map((_message: ChatMessageResponseElement) => {

			function onDeleteMessage(e?): void {
				if (e) {
					e.preventDefault();
				}
				deleteMessage(_message).then().catch();
			}

			return (
				<div className="mx-2 my-2">
					{!_message.isOwnMessage ? (
						<div className="chat-bubble-width">
							<p className="mb-0 ml-1 text-muted">
								{_message.chatMessage.name}
							</p>
						</div>
					) : (
						<div className="d-flex justify-content-end">
							<p className="mb-0 mr-1 text-muted">You</p>
						</div>
					)}

					<div className={"d-flex flex-column " + (_message.isOwnMessage ? "align-items-end" : "align-items-start")}>
						<div className={"chat-bubble chat-bubble-width " + (_message.isOwnMessage ? "chat-bubble-own" : "chat-bubble-other")} lang="en">
							{_message.chatMessage.message}
						</div>

						<div style={{marginTop: -3, paddingLeft: 3}}>
							<a className="text-danger d-inline-flex" href="#" onClick={onDeleteMessage}>
								<p style={{fontSize: "0.8rem",}} className="mb-0">
									delete
								</p>
							</a>
						</div>
					</div>

				</div>
			);
		});
	}

	return (
		<React.Fragment>
			{mapMessages(props.messages)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
	}
})(MessageList);
