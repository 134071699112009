import React, {useState} from "react";
import {Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import {CreateTournamentBody, TeeTimeStartType, TournamentStartType} from "client";
import SelectOptionsFactory, {ISelectOption} from "../forms/SelectOptionsFactory";
import {generateOffsetDate, convertOffsetDate} from "../../utils/dateUtils";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import {endOfDay, isSameDay, startOfToday} from "date-fns";
import {handleDateChangeRaw} from "../../utils/handleDateChangeRaw";

interface IProps {
	tournament: Partial<CreateTournamentBody>;
	editing: boolean; // true if parent form has tournament via props, which means this form is being used to edit, not create
	onTextChange(key: keyof CreateTournamentBody): (e) => void;
	irregularOnChange(key: keyof CreateTournamentBody, value: any): void;
}

const TournamentInformation: React.FC<IProps> = (props: IProps) => {

	const {tournament} = props;
	const [minTime, setMinTime] = useState(calculateMinTime(new Date()));

	function calculateMinTime(date) {
		return isSameDay(date, new Date()) ? new Date() : startOfToday();
	}

	function dateOnChange(t): void {
		setMinTime(calculateMinTime(convertOffsetDate(t)));
		props.irregularOnChange("startTime", convertOffsetDate(tournament.timeZone, t));
	}

	function handleMidChanges(t): void {
		setMinTime(calculateMinTime(t));
	}

	function toggleChat(): void {
		let newVal: boolean = true;
		if (tournament.hasChat === true) {
			newVal = false;
		}
		props.irregularOnChange("hasChat", newVal);
	}

	function toggleSkipOpeningVideo(): void {
		let newVal: boolean = true;
		if (tournament.skipOpeningVideo === true) {
			newVal = false;
		}
		props.irregularOnChange("skipOpeningVideo", newVal);
	}

	function toggleEarlyAccess(): void {
		let newVal: boolean = true;
		if (tournament.allowAnytimeAccess === true) {
			newVal = false;
		}
		props.irregularOnChange("allowAnytimeAccess", newVal);
	}

	function toggleGenderDifferences(): void {
		let newVal: boolean = true;
		if (tournament.isMixedGender === true) {
			newVal = false;
		}
		props.irregularOnChange("isMixedGender", newVal);
	}

	function toggleStartType(_startType: TournamentStartType): () => void {
		return () => {
			props.irregularOnChange("startType", _startType);
		}
	}

	function onTeeTimeStartTypeChange(e): void {
		props.irregularOnChange("teeTimeStartType", e.target.value);
	}

	function onTeamSizeChange(e): void {
		props.irregularOnChange("teamSize", parseInt(e.target.value));
	}

	return (
		<Card>
			<CardHeader>Tournament Information</CardHeader>
			<CardBody>
				<div className="mb-3">
					<Label>Tournament Name</Label>
					<Input placeholder="Tournament Name" value={tournament.name} onChange={props.onTextChange("name")}/>
				</div>

				<div className="mb-3">
					<Label>Timezone</Label><span>{" "}(Cannot be edited after saving tournament)</span>
					<Input
						type="select"
						placeholder="Timezone"
						value={tournament.timeZone}
						onChange={props.editing ? () => {} : props.onTextChange("timeZone")}
						disabled={props.editing}
					>
						<option selected disabled value="">Select Timezone</option>
						<hr/>
						<SelectOptionsFactory strings={moment.tz.names().filter((tz) => {
							return tz.includes("Canada/") || tz.includes("US/");
						})}/>
					</Input>
				</div>

				<div className="mb-3">
					<Label>Start Time</Label>
					<div className="react-date-picker-helper">
						{tournament.timeZone ? (
							<DatePicker
								placeholderText="Select Date & Time"
								selected={new Date(generateOffsetDate(tournament.timeZone, tournament.startTime))}
								showTimeSelect={true}
								showMonthDropdown={true}
								showYearDropdown={true}
								onChange={dateOnChange}
								onMonthChange={handleMidChanges}
								onYearChange={handleMidChanges}
								dateFormat="MMMM d, yyyy h:mm aa"
								minDate={new Date(generateOffsetDate(tournament.timeZone))}
								excludeOutOfBoundsTimes={true}
								customInput={<Input/>}
								onChangeRaw={handleDateChangeRaw}
								timeIntervals={5}
								popperPlacement="bottom-start"
								popperModifiers={{
									flip: {
										behavior: ["bottom"] // don't allow it to flip to be above
									},
									preventOverflow: {
										enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
									},
									hide: {
										enabled: false // turn off since needs preventOverflow to be enabled
									}
								}}
							/>
						) : (
							<p className="font-italic">
								Please select a timezone before choosing a tournament start time.
							</p>
						)}
					</div>
				</div>

				<div className="mb-3">
					<Label>Tournament Start Type</Label>
					<div className="d-flex">
						<div className="mr-5">
							<p className="mb-1">
								<input
									type="checkbox"
									name="teeTimeCheckbox"
									id="teeTimeCheckbox"
									checked={tournament.startType === TournamentStartType.TEETIME}
									onChange={toggleStartType(TournamentStartType.TEETIME)}
								/>
								<label htmlFor="teeTimeCheckbox">Tee-Time</label>
							</p>
						</div>

						<div>
							<p className="mb-1">
								<input
									type="checkbox"
									name="shotgunCheckbox"
									id="shotgunCheckbox"
									checked={tournament.startType === TournamentStartType.SHOTGUN}
									onChange={toggleStartType(TournamentStartType.SHOTGUN)}
								/>
								<label htmlFor="shotgunCheckbox">Shotgun</label>
							</p>
						</div>

					</div>

					{tournament.startType === TournamentStartType.TEETIME && (
						<div>
							<Input type="select" placeholder="Tee-Time Start Type" value={tournament.teeTimeStartType} onChange={onTeeTimeStartTypeChange}>
								<option value={TeeTimeStartType.ONE}>Hole 1</option>
								<option value={TeeTimeStartType.TEN}>Hole 10</option>
								<option value={TeeTimeStartType.ONEANDTEN}>Holes 1 & 10</option>
							</Input>
						</div>
					)}
				</div>

				<div className="mb-3">
					<Label>Gendered Differences</Label>
					<p>
						<input
							type="checkbox"
							name="enableGenderedDifferences"
							id="enableGenderedDifferences"
							checked={tournament.isMixedGender === true}
							onChange={toggleGenderDifferences}
						/>
						<label htmlFor="enableGenderedDifferences">Use different values for Male / Female Course & Slope Ratings, pars, and handicaps.</label>
					</p>
				</div>

				<div className="mb-3">
					<Label>Team Size</Label>
					<Input type="select" placeholder="Tournament Description" value={tournament.teamSize} onChange={onTeamSizeChange}>
	                    {/*<option value={0}>Individual Play</option>*/}
	                    {/*<hr/>*/}
	                    <SelectOptionsFactory options={[2, 3, 4, 5, 6].map((n, i): ISelectOption => {return {value: n, label: n.toString()}})}/>
	                </Input>
				</div>

				<div className="mb-3">
					<Label>Tournament Chat</Label>
					<p>
						<input
							type="checkbox"
							name="enableChat"
							id="enableChat"
							checked={tournament.hasChat === true}
							onChange={toggleChat}
						/>
						<label htmlFor="enableChat">Users can chat.</label>
					</p>
				</div>

				<div className="mb-3">
					<Label>Opening Video</Label>
					<p>
						<input
							type="checkbox"
							name="skipOpeningVideo"
							id="skipOpeningVideo"
							checked={tournament.skipOpeningVideo === true}
							onChange={toggleSkipOpeningVideo}
						/>
						<label htmlFor="skipOpeningVideo">Opening video is skipped.</label>
					</p>
				</div>

				<div className="mb-3">
					<Label>Allow Early Access</Label>
					<p>
						<input
							type="checkbox"
							name="enableEarlyAccess"
							id="enableEarlyAccess"
							checked={tournament.allowAnytimeAccess === true}
							onChange={toggleEarlyAccess}
						/>
						<label htmlFor="enableEarlyAccess">If enabled, players will be able to access their portal with their link as soon as they get it. Leave this option un-checked to disable access until 3 hours before the tournament begins.</label>
					</p>
				</div>

			</CardBody>
		</Card>
	);
};

export default TournamentInformation;
