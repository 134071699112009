import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Tournament, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import {useQuery} from "../utils/useQuery";
import {addError} from "../redux/meta/MetaActions";
import TournamentForm from "../components/TournamentForm/TournamentForm";
import {Button, Container} from "reactstrap";
import {useHistory} from "react-router";
import ConfirmResetTournamentScoresModal from "../components/modals/ConfirmResetTournamentScoresModal";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TournamentDetails: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const [res, setRes] = useState<Tournament>();
	const [showConfirmReset, setShowConfirmReset] = useState(false);

	useEffect(() => {
		getDetails().then().catch();
	}, [tournamentID]);

	if (!tournamentID) {
		history.replace("/iscoregolfplus");
		return null;
	}

	function toggleShowConfirmReset(): void {
		setShowConfirmReset(!showConfirmReset);
	}

	async function getDetails(): Promise<void> {
		try {
			const res = await new TournamentApi(getConfig(props.token)).getTournament({tournamentID});
			setRes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	/**
	 * Hide the modal for confirming resetting the scores, and call the api for new details.
	 *
	 */
	async function onDoneResettingScore(): Promise<void> {
		setShowConfirmReset(false);
		await getDetails();
	}

	return (
		<React.Fragment>
			<ConfirmResetTournamentScoresModal
				isOpen={showConfirmReset}
				tournamentID={tournamentID}
				onClose={toggleShowConfirmReset}
				onDone={onDoneResettingScore}
			/>

			<Container className="my-5 create-tournament-form">
				<h1>
					Edit Tournament Details
				</h1>
				<TournamentForm tournament={res} onDoneUpdate={getDetails}/>

				<hr/>
				<div style={{height: "2rem"}}/>

				<div className="d-flex justify-content-center mb-3">
					<Button color="danger" onClick={toggleShowConfirmReset}>
						Reset Scores
					</Button>
				</div>

				<div style={{height: "2rem"}}/>
			</Container>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentDetails);
