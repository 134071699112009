import {IAction} from "../IAction";
import {TournamentListAction} from "./TournamentListReducer";
import {Tournament, TournamentApi} from "client";
import getConfig from "../../utils/getConfig";
import {store} from "../index";
import {addError, decrementLoading, incrementLoading} from "../meta/MetaActions";

export async function updateTournamentList(token: string): Promise<IAction<TournamentListAction.UPDATE, Array<Tournament>>> {

	store.dispatch(incrementLoading());

	let res = [];

	try {
		res = await new TournamentApi(getConfig(token)).getOwnTournaments({isActive: false});
	} catch (e) {
		store.dispatch(await addError(e));
	}

	store.dispatch(decrementLoading());

	return {
		type: TournamentListAction.UPDATE,
		payload: res,
	}
}
