import React from "react";

const RequiredAsterisk: React.FC = () => {

	return (
		<span className="text-danger">*</span>
	);
};

export default RequiredAsterisk;
