import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AddPlayerBody, EditPlayerBody, Player, PlayerGroupings, Team, TeamWithPlayers, TournamentApi} from "client";
import SelectOptionsFactory, {ISelectOption} from "../forms/SelectOptionsFactory";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	tournamentID: string;
	teams: Array<TeamWithPlayers>;
	defaultTeam?: TeamWithPlayers;
	player?: Player;
	playerGroupings: PlayerGroupings;
	onClose(): void;
	onDone(): Promise<void>;
}

const defaultAddPlayerForm: Partial<AddPlayerBody> = {
	firstName: "",
	lastName: "",
	phoneNumber: "",
	email: "",
	teamID: "",
	male: "true" as unknown as boolean,
	handicap: "" as unknown as number,
	handicapIndex: "" as unknown as number,
};

const AddPlayerModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, tournamentID, teams, defaultTeam, player} = props;
	const [form, setForm] = useState<Partial<AddPlayerBody>>(defaultAddPlayerForm);
	const [useHandicapIndex, setUseHandicapIndex] = useState(false);
	const [isEnteringHandicapIndex, setIsEnteringHandicapIndex] = useState(false);
	const [isLoadingPlayingHandicap, setIsLoadingPlayingHandicap] = useState(false);
	const [playingHandicap, setPlayingHandicap] = useState();
	const [smsConsent, setSmsConsent] = useState(true);

	useEffect(() => {
		if (isOpen && defaultTeam) {
			setForm({
				...form,
				teamID: defaultTeam._id,
			});
		} else if (isOpen && props.player) {
			setForm({
				firstName: player.firstName,
				lastName: player.lastName,
				phoneNumber: player.phoneNumber,
				email: player.email,
				teamID: player.team ? player.team._id : "",
				male: player.male === true ? "true" as any : (player.male === false ? "false" as any : "") as unknown as boolean,
				handicap: player.handicap,
				handicapIndex: player.handicapIndex,
			});
			if (player.handicapIndex !== undefined) {
				setUseHandicapIndex(true);
				handleHandicapIndexAPI(player.handicapIndex).then().catch();
			}
		}
	}, [isOpen]);

	useEffect(() => {
		setPlayingHandicap(undefined);
	}, [useHandicapIndex]);

	function resetAndClose(): void {
		setForm(defaultAddPlayerForm);
		setUseHandicapIndex(false);
		props.onClose();
	}

	function onTeamChange(e: any): void {
		setForm({
			...form,
			teamID: e.target.value,
		});
	}

	function onChange(key: keyof Player): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function onSexChange(e): void {
		const s: string = e.target.value;
		setForm({
			...form,
			male: s === "" ? ("" as unknown as boolean) : s as any,
		})
	}

	function toggleHandicapIndex(): void {
		setUseHandicapIndex(!useHandicapIndex);
		setForm({
			...form,
			handicap: "" as unknown as number,
			handicapIndex: "" as unknown as number,
		})
	}

	function onHandicapInputChange(e): void {
		let val: number = e.floatValue;

		setForm({
			...form,
			[useHandicapIndex ? "handicapIndex" : "handicap"]: val,
		});
	}

	async function savePlayer(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const addPlayerBody: Partial<AddPlayerBody> = {
				firstName: form.firstName,
				lastName: form.lastName,
				tournamentID,
			};
			if (form.teamID) {addPlayerBody.teamID = form.teamID}
			if (form.phoneNumber) {addPlayerBody.phoneNumber = form.phoneNumber}
			if (form.email) {addPlayerBody.email = form.email}
			if (form.male as unknown as string !== "") {addPlayerBody.male = (form.male as any === "true")}
			if (!props.playerGroupings.isMixedGender) {addPlayerBody.male = undefined}
			if (useHandicapIndex) {
				addPlayerBody.handicapIndex = form.handicapIndex;
				addPlayerBody.handicap = undefined;
			} else {
				addPlayerBody.handicap = form.handicap;
				addPlayerBody.handicapIndex = undefined;
			}
			if (player && player._id) {
				await new TournamentApi(getConfig(token)).editPlayer({editPlayerBody: {...addPlayerBody, playerID: player._id} as EditPlayerBody});
			} else {
				await new TournamentApi(getConfig(token)).addPlayer({addPlayerBody: addPlayerBody as AddPlayerBody});
			}

			props.dispatch(decrementLoading());
			resetAndClose();
			props.onDone().then().catch();

		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	function onHandicapIndexFocus(): void {
		setIsEnteringHandicapIndex(true);
	}

	function onHandicapIndexBlur(): void {
		setIsEnteringHandicapIndex(false);

		if (form.handicapIndex as any !== undefined) {
			handleHandicapIndexAPI().then().catch();
		} else {
			setPlayingHandicap(undefined);
		}
	}

	async function handleHandicapIndexAPI(forceValue?: number): Promise<void> {
		setIsLoadingPlayingHandicap(true);

		try {
			const res = await new TournamentApi(getConfig(props.token)).getPlayerHandicapCalculation({
				tournamentID,
				handicapIndex: forceValue ? forceValue : form.handicapIndex,
				isMale: form.male,
			});

			setPlayingHandicap(res);
		} catch (e) {
		}
		setIsLoadingPlayingHandicap(false);
	}

	function getPlayingHandicapDisplay(): string {
		if (isEnteringHandicapIndex) {
			return "Please finish typing first"
		} else if (isLoadingPlayingHandicap) {
			return "Loading..."
		} else if (playingHandicap !== undefined) {
			return playingHandicap.toString();
		}

		return "-"
	}

	function toggleSmsConsent(): void {
		setSmsConsent((curr) => !curr);
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
		>
			<ModalHeader toggle={resetAndClose}>{player ? "Edit Player" : "Add Player"}</ModalHeader>

			<ModalBody>
				<div className="mb-3">
					<Label>Team</Label>
					<Input type="select" placeholder="Team" value={form.teamID} onChange={onTeamChange}>
						<option selected value="">Unassigned</option>
						<hr/>
						<SelectOptionsFactory options={teams.map((team: TeamWithPlayers): ISelectOption => {
							let disabled: boolean = team.players.length >= props.playerGroupings.teamSize;
							if ((player && player.team && player.team._id) === team._id) {
								disabled = false;
							}
							return {
								value: team._id,
								label: team.name + (disabled ? " - (Team is full)" : ""),
								disabled,
							}
						})}/>
					</Input>
				</div>

				<div className="mb-3">
					<Label>First Name</Label>
					<Input placeholder="Player First Name" value={form.firstName}
					       onChange={onChange("firstName")}/>
				</div>

				<div className="mb-3">
					<Label>Last Name</Label>
					<Input placeholder="Player Last Name" value={form.lastName}
						   onChange={onChange("lastName")}/>
				</div>

				{props.playerGroupings.isMixedGender && (
					<div className="mb-3">
						<Label>Gender</Label>
						<Input type="select" placeholder="Gender" value={form.male as unknown as string} onChange={onSexChange}>
							{/*<option selected value="" disabled>Select</option>*/}
							{/*<hr/>*/}
							<option selected value="true">Male</option>
							<option selected value="false">Female</option>
						</Input>
					</div>
				)}

				{props.playerGroupings.useHandicap && (
					<div className="mb-3">
						<Label>Handicap</Label>
						<p className="mb-1">
							<input
								type="checkbox"
								name="toggleHandicapIndex"
								id="toggleHandicapIndex"
								checked={useHandicapIndex === true}
								onChange={toggleHandicapIndex}
							/>
							<label htmlFor="toggleHandicapIndex">Using Handicap Index</label>
						</p>
						{useHandicapIndex ? (
							<NumberFormat
								placeholder={"Handicap Index"}
								value={form.handicapIndex}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={true}
								decimalScale={1}
								onValueChange={onHandicapInputChange}
								onFocus={onHandicapIndexFocus}
								onBlur={onHandicapIndexBlur}
							/>
						) : (
							<NumberFormat
								placeholder={"Handicap"}
								value={form.handicap}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={true}
								decimalScale={0}
								onValueChange={onHandicapInputChange}
							/>
						)}
						{/*<NumberFormat*/}
						{/*	placeholder={useHandicapIndex ? "Handicap Index" : "Handicap"}*/}
						{/*	value={useHandicapIndex ? form.handicapIndex : form.handicap}*/}
						{/*	customInput={Input}*/}
						{/*	allowLeadingZeros={false}*/}
						{/*	allowNegative={true}*/}
						{/*	decimalScale={useHandicapIndex ? 1 : 0}*/}
						{/*	onValueChange={onHandicapInputChange}*/}
						{/*/>*/}
						{(useHandicapIndex === true) && (
							<p className="text-muted font-italic">{`Playing Handicap: `}{getPlayingHandicapDisplay()}</p>
						)}
					</div>
				)}

				<div className="mb-3">
					<Label>Phone Number</Label>
					<Input placeholder="Player Phone Number" value={form.phoneNumber}
					       onChange={onChange("phoneNumber")} type="tel"/>
				</div>

				<div className="mb-3">
					<Label>Email Address</Label>
					<Input placeholder="Player Email Address" value={form.email}
					       onChange={onChange("email")} type="email"/>
				</div>

				<div>
					<input
						type="checkbox"
						name="smsConsent"
						id="smsConsent"
						checked={smsConsent}
						onChange={toggleSmsConsent}
					/>
					<label htmlFor="smsConsent">You consent to receive SMS messages (including text messages), from us, with the number you have provided, the purpose of this text is for use of the iScoreGolf Golf Scoring platform and will not be used for marketing purposes.</label>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button
					color="darkBlue"
					onClick={savePlayer}
					disabled={form.phoneNumber && !smsConsent}
				>
					Save Player
				</Button>
			</ModalFooter>
		</Modal>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddPlayerModal);
