import React, {ReactNode, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Player} from "client";
import {Button} from "reactstrap";
import SendSMSModal from "../modals/SendSMSModal";
import SendEmailModal from "../modals/SendEmailModal";
import moment from "moment";

interface IProps {
	data: Array<Player>;
	onDone(): Promise<void>;
}

const PlayerPairingsTable: React.FC<IProps> = (props: IProps) => {

	const {data} = props;
	
	function makeActionSMSButtons(cell: string, row: Player): ReactNode {
		return (<PairingsSMSButtons player={row} onDone={props.onDone}/>)
	}

	function makeActionEmailButtons(cell: string, row: Player): ReactNode {
		return (<PairingEmailButtons player={row} onDone={props.onDone}/>)
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			noDataIndication={data === undefined ? "Loading..." : "No Players"}
			defaultSorted={[{dataField: "startingHole", order: "asc"}]}
			data={data ? data : []}
			keyField="_id"
			columns={[
				{
					dataField: "name",
					text: "Name",
					sort: true,
					formatter: (cell: string, row: Player) => {
						return row.firstName + " " + row.lastName;
					},
					sortFunc: (a, b, order, dataField, playerA: Player, playerB: Player) => {
						const aCompare: string = playerA.firstName + " " + playerA.lastName;
						const bCompare: string = playerB.firstName + "" + playerB.lastName;
						if (order === 'asc') {
							return aCompare.localeCompare(bCompare)
						}
						return bCompare.localeCompare(aCompare)
					}
				},
				{
					dataField: "smsAction",
					text: "SMS",
					formatter: makeActionSMSButtons,
				},
				{
					dataField: "emailAction",
					text: "Email",
					formatter: makeActionEmailButtons,
				},
				{
					dataField: "phoneNumber",
					text: "Phone Number",
					sort: true,
				},
				{
					dataField: "email",
					text: "Email",
					sort: true,
				},
				{
					dataField: "startingHole",
					text: "Starting Hole",
					sort: true,
					formatter: (cell: string, row: Player) => {
						return (row.team && Object.keys(row.team).length > 0) ? (row.team.identifier ? row.team.startHole + row.team.identifier : row.team.startHole) : "-";
					},
					sortFunc: (a, b, order, dataField, playerA: Player, playerB: Player) => {

						const aHole: number = (playerA.team && Object.keys(playerA.team).length > 0) ? playerA.team.startHole : 0;
						const bHole: number = (playerB.team && Object.keys(playerB.team).length > 0) ? playerB.team.startHole : 0;

						const aIdentifier: string = (playerA.team && Object.keys(playerA.team).length > 0 && playerA.team.identifier) ? playerA.team.identifier : "";
						const bIdentifier: string = (playerB.team && Object.keys(playerB.team).length > 0 && playerB.team.identifier) ? playerB.team.identifier : "";

						// if same starting hole, compare the identifier, else just use hole number
						if (aHole === bHole) {
							if (order === 'asc') {
								return aIdentifier.localeCompare(bIdentifier)
							}
							return bIdentifier.localeCompare(aIdentifier)
						} else {
							if (order === 'asc') {
								return aHole - bHole;
							}
							return bHole - aHole;
						}

					}
				},
				{
					dataField: "startTime",
					text: "Start Time",
					sort: true,
					formatter: (cell: string, row: Player) => {
						return (row.team && Object.keys(row.team).length > 0) ? moment(row.team.startTime).format("h:mm A") : "-";
					},
				},
				{
					dataField: "lastDateMessageWasSent",
					text: "Was Sent Message",
					sort: true,
					formatter: (cell: string, row: Player) => {
						return row.lastDateMessageWasSent ? "✓" : "x";
					},
					classes: (cell: string, row: Player) => (row.lastDateMessageWasSent ? "text-success" : "text-danger") + " text-center sent-message-pairing-column",
				},
				{
					dataField: "lastDateEmailWasSent",
					text: "Was Sent Email",
					sort: true,
					formatter: (cell: string, row: Player) => {
						return row.lastDateEmailWasSent ? "✓" : "x";
					},
					classes: (cell: string, row: Player) => (row.lastDateEmailWasSent ? "text-success" : "text-danger") + " text-center sent-message-pairing-column",
				},
				{
					dataField: "urlExtension",
					text: "URL Extension",
					sort: false,
					formatter: (cell: string) => {
						let link: string = process.env.REACT_APP_PLAYER_PORTAL_URL + `/${cell}`;

						return (<a href={link} target="_blank" rel="noreferrer noopener">{link}</a>);
					},
				},
			]}
		/>
	);
};

interface IPairingsSMSButtonsProps {
	player: Player;
	onDone(): Promise<void>;
}

const PairingsSMSButtons: React.FC<IPairingsSMSButtonsProps> = (props: IPairingsSMSButtonsProps) => {

	const {player} = props;
	const [showSMSModal, setShowSMSModal] = useState(false);

	function toggleShowSMSModal(): void {
		setShowSMSModal(!showSMSModal);
	}

	return (
		<React.Fragment>
			<SendSMSModal
				isOpen={showSMSModal}
				player={player}
				onClose={toggleShowSMSModal}
				onDone={props.onDone}
			/>

			<div className="d-flex">
				<Button color="lightBlue" disabled={!player.phoneNumber} onClick={toggleShowSMSModal} className="mr-3">
					Send SMS
				</Button>
			</div>
		</React.Fragment>
	);
};

interface IPairingEmailButtonsProps {
	player: Player;
	onDone(): Promise<void>;
}

const PairingEmailButtons: React.FC<IPairingEmailButtonsProps> = (props: IPairingEmailButtonsProps) => {

	const {player} = props;
	const [showEmailModal, setShowEmailModal] = useState(false);

	function toggleShowEmailModal(): void {
		setShowEmailModal(!showEmailModal);
	}

	return (
		<React.Fragment>
			<SendEmailModal
				isOpen={showEmailModal}
				player={player}
				onClose={toggleShowEmailModal}
				onDone={props.onDone}
			/>

			<div className="d-flex">
				<Button color="lightBlue" disabled={!player.email} onClick={toggleShowEmailModal} className="mr-3">
					Send Email
				</Button>
			</div>
		</React.Fragment>
	);
};

export default PlayerPairingsTable;
