import React, {ReactElement, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Player, TournamentApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import classNames from "classnames";

interface IProps {
	dispatch?: any;
	token?: string;
    isOpen: boolean;
    player: Player;
    onClose: () => void;
    onDone: () => Promise<void>;
}

function EditPlayerConfirmSendTextModal(props: IProps): ReactElement {

    const [useSMS, setUseSMS] = useState(false);
    const [useEmail, setUseEmail] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            if (props.player.phoneNumber?.length > 0 && props.player.lastDateMessageWasSent === undefined) {
                setUseSMS(true);
            }

            if (props.player.email?.length > 0 && props.player.lastDateEmailWasSent === undefined) {
                setUseEmail(true);
            }
        }
    }, [JSON.stringify(props.player)]);

    useEffect(() => {
        if (!props.isOpen) {
            setUseSMS(false);
            setUseEmail(false);
        }
    }, [props.isOpen]);

    function toggleSendConfig(type: "sms" | "email"): () => void {
        return () => {
            if (type === "sms") {
                setUseSMS(u => !u);
            } else if (type === "email") {
                setUseEmail(u => !u);
            }
        }
    }

    async function sendText(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new TournamentApi(getConfig(props.token)).sendPlayerURL({
                sendPlayerURLBody: {
                    playerID: props.player._id,
                    useSMS: useSMS,
                    useEmail: useEmail,
                },
            });

            await props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

	return (
        <Modal
            isOpen={props.isOpen}
            centered={true}
            contentClassName="edit-player-confirm-send-url-modal"
        >
            <ModalHeader toggle={props.onClose}>Confirm Sending URL to Player</ModalHeader>
            <ModalBody>
                <p>
                    {`Are you sure you want to send the tournament URL to ${props.player.firstName} ${props.player.lastName}?`}
                </p>

                <div
                    className={classNames({
                        "edit-player-confirm-send-url-modal_disabled-check": props.player.phoneNumber == undefined || props.player.lastDateMessageWasSent !== undefined,
                    })}
                >
                    <input
                        type="checkbox"
                        name="useSMS"
                        id="useSMS"
                        checked={useSMS}
                        onChange={toggleSendConfig("sms")}
                    />
                    <label htmlFor="useSMS">
                        Send Text Message
                        {props.player.phoneNumber == undefined && " (Player has no phone number set.)"}
                        {props.player.lastDateMessageWasSent !== undefined && " (Player has already been sent a text message at their current number.)"}
                    </label>
                </div>

                <div
                    className={classNames({
                        "edit-player-confirm-send-url-modal_disabled-check": props.player.email == undefined || props.player.lastDateEmailWasSent !== undefined,
                    })}
                >
                    <input
                        type="checkbox"
                        name="useEmail"
                        id="useEmail"
                        checked={useEmail}
                        onChange={toggleSendConfig("email")}
                    />
                    <label htmlFor="useEmail">
                        Send Email
                        {props.player.email == undefined && " (Player has no email set.)"}
                        {props.player.lastDateEmailWasSent !== undefined && " (Player has already been sent an email at their current email address.)"}
                    </label>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="gray" className="mr-3" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button color="yellow" className="text-white" onClick={sendText} disabled={!useSMS && !useEmail}>
                    Yes, Send URL
                </Button>
            </ModalFooter>
        </Modal>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EditPlayerConfirmSendTextModal);