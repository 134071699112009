import React from "react";
import {GolfCourse, GolfCourseType} from "client";
import {isNil} from "lodash";
import GolfCourseInput from "./GolfCourseInput";
import {Col, Row} from "reactstrap";

interface IProps {
	golfCourse: GolfCourse;
}

const GolfCourseView: React.FC<IProps> = (props) => {

	const {golfCourse} = props;
	// check what information is available for conditional rendering
	const hasGolfClub = golfCourse.golfClub && golfCourse.golfClub.length > 0;
	const hasAddress = golfCourse.address && golfCourse.address.length > 0
	const hasProvince = golfCourse.province && golfCourse.province.length > 0;
	const hasCountry = golfCourse.country && golfCourse.country.length > 0;
	const hasLocation = hasAddress && hasProvince && hasCountry;
	const hasHolesInformation = Object.keys(golfCourse.holes).length > 0;

	const hasCourseRating = golfCourse.courseRating && golfCourse.courseRating.toString().length > 0;
	const hasMaleCourseRating = golfCourse.maleCourseRating && golfCourse.maleCourseRating.toString().length > 0;
	const hasFemaleCourseRating = golfCourse.femaleCourseRating && golfCourse.femaleCourseRating.toString().length > 0;
	const hasSlopeRating = golfCourse.courseRating && golfCourse.courseRating.toString().length > 0;
	const hasMaleSlopeRating = golfCourse.maleSlopeRating && golfCourse.maleSlopeRating.toString().length > 0;
	const hasFemaleSlopeRating = golfCourse.femaleSlopeRating && golfCourse.femaleSlopeRating.toString().length > 0;
	const hasCourseRatingOrSlopeRating = hasCourseRating || hasMaleCourseRating || hasFemaleCourseRating || hasSlopeRating || hasMaleSlopeRating || hasFemaleSlopeRating;

	// find out if there is info on handicap or par
	let hasParInfo = false;
	let hasHandicapInfo = false;

	if (hasHolesInformation) {
		const holeValues = Object.values(golfCourse.holes);
		for (const hole of holeValues) {
			if (!isNil(hole.par) || !isNil(hole.malePar) || !isNil(hole.femalePar)) {
				hasParInfo = true;
			}

			if (!isNil(hole.handicap) || !isNil(hole.maleHandicap) || !isNil(hole.femaleHandicap)) {
				hasHandicapInfo = true;
			}

			// break out early
			if (hasParInfo && hasHandicapInfo) {
				break;
			}
		}
	}


	return (
		<React.Fragment>
			{hasGolfClub && <h3>{golfCourse.golfClub}</h3>}

			<Row>
				<Col xs={12} sm={12} md={12} lg={4} className="mb-3">
					{hasLocation && (
						<h5>
							{hasAddress ? golfCourse.address + ", " : ""}
							{hasProvince ? golfCourse.province + ", " : ""}
							{hasCountry ? golfCourse.country : ""}
						</h5>
					)}

				</Col>

				{hasCourseRatingOrSlopeRating && (
					<React.Fragment>
						{golfCourse.golfCourseType === GolfCourseType.HANDICAPGENDERED ? (
							<>
								<Col xs={12} sm={6} md={6} lg={4} className="mb-3">
									<h5>
										<p>{"Male Course Rating: " + getRatingValue(golfCourse.maleCourseRating)}</p>
										<p>{"Female Course Rating: " + getRatingValue(golfCourse.femaleCourseRating)}</p>
									</h5>
								</Col>
								<Col xs={12} sm={6} md={6} lg={4} className="mb-3">
									<h5>
										<p>{"Male Slope Rating: " + getRatingValue(golfCourse.maleSlopeRating)}</p>
										<p>{"Female Slope Rating: " + getRatingValue(golfCourse.femaleSlopeRating)}</p>
									</h5>
								</Col>
							</>
						) : ((golfCourse.golfCourseType === GolfCourseType.HANDICAPNONGENDERED) ? (
							<Col xs={12} sm={6} md={4} className="mb-3">
								<h5>
									<p>{"Course Rating: " + getRatingValue(golfCourse.courseRating)}</p>
									<p>{"Slope Rating: " + getRatingValue(golfCourse.slopeRating)}</p>
								</h5>
							</Col>
						) : null)}
					</React.Fragment>
				)}
			</Row>

			{hasHolesInformation && (
				<div className="overflow-x-scroll">
					<GolfCourseInput
						golfCourseType={golfCourse.golfCourseType}
						holes={golfCourse.holes}
						editPar={false}
						showPar={hasParInfo}
						editHandicap={false}
						showHandicap={hasHandicapInfo}
						selectable={false}
					/>
				</div>
			)}
		</React.Fragment>
	)
};

function getRatingValue(rating: number): string {
	if (rating && rating.toString().length > 0) {
		return rating.toString();
	}

	return "-"
}

export default GolfCourseView;
