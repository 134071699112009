import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FiAlertCircle} from "react-icons/all";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import {useHistory} from "react-router";
import {updateTournamentList} from "../redux/tournamentList/TournamentListActions";

interface IProps {
	token?: string;
	dispatch?: any;
	tournamentID: string;
}

const UserDeleteTournamentHandler: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [showFirst, setShowFirst] = useState(false);
	const [showSecond, setShowSecond] = useState(false);
	const [displayTime, setDisplayTime] = useState({n: 5000}); // nested in an object because can't user ref "current" value with numbers
	const displayTimeRef = useRef(displayTime);
	displayTimeRef.current = displayTime;

	useEffect(() => {
		let interval;
		if (showSecond) {
			interval = setInterval(() => {
				if (displayTimeRef.current.n < 1000) {
					clearInterval(interval);
				}
				setDisplayTime({n: displayTimeRef.current.n - 1000})
			}, 1000);
		} else {
			setDisplayTime({n: 5000});
			if (interval) {
				clearInterval(interval);
			}
		}

		return function cleanUp() {
			clearInterval(interval);
		}
	}, [showSecond]);

	async function deleteTournament(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(props.token)).userDisableTournament({iDBody: {id: props.tournamentID}});
			await props.dispatch(updateTournamentList(props.token));
			history.push("/iscoregolfplus");
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function onClickButton(): void {
		setShowFirst(true);
	}

	function cancelFirst(): void {
		setShowFirst(false);
	}

	function confirmFirst(): void {
		setShowSecond(true);
	}

	function cancelSecond(): void {
		setShowSecond(false);
	}

	const waitDisplay: number = Math.floor(displayTimeRef.current.n / 1000);

	return (
		<React.Fragment>
			<Modal
				isOpen={showFirst}
				centered={true}
				fade={false}
				toggle={cancelFirst}
			>
				<ModalHeader toggle={cancelFirst}>Delete Tournament</ModalHeader>

				<ModalBody>
					<p>
						Are you sure you want to delete this tournament?
					</p>
				</ModalBody>

				<ModalFooter>
					<Button color="gray" className="mr-3" onClick={cancelFirst}>
						Cancel
					</Button>
					<Button color="danger" onClick={confirmFirst}>
						Yes, Delete Tournament
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={showSecond}
				centered={true}
				fade={false}
				toggle={cancelSecond}
			>
				<ModalHeader toggle={cancelSecond}>Confirm Deletion</ModalHeader>

				<ModalBody>
					<p>
						Are you <b>ABSOLUTELY</b> sure you want to <b>DELETE</b> this tournament?
					</p>
				</ModalBody>

				<ModalFooter className="d-flex justify-content-between">
					{(waitDisplay > 0) ? (
						<span>
							{`Please wait ${waitDisplay} second${waitDisplay === 1 ? "" : "s"}...`}
						</span>
					) : (
						<Button color="danger" className="d-flex align-items-center" onClick={deleteTournament}>
							<FiAlertCircle size="1.25rem" className="mr-1"/>Delete
						</Button>
					)}

					<Button color="gray" onClick={cancelSecond}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			<div className="d-flex justify-content-center">
				<Button color="yellow" className="text-white" onClick={onClickButton}>
					Delete this Tournament
				</Button>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UserDeleteTournamentHandler);
