import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Player, TournamentApi, UserApi} from "client";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	player: Player;
	onClose(): void;
	onDone(): Promise<void>;
}

const SendSMSModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, player} = props;

	async function sendSMS(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(token)).sendPlayerURL({
				sendPlayerURLBody: {
					playerID: player._id,
					useEmail: false,
					useSMS: true,
				}
			});

			await props.onDone();
			props.onClose();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
		>
			<ModalHeader>{`Confirm SMS for:${(player.firstName ? (" " + player.firstName) : "") + (player.lastName ? (" " + player.lastName) : "")}`}</ModalHeader>

			<ModalBody>
				<p>
					Send this player their tournament link over SMS?
				</p>
			</ModalBody>

			<ModalFooter>
				<Button color="gray" onClick={props.onClose}>
					Cancel
				</Button>

				<Button color="darkBlue" onClick={sendSMS}>
					Send SMS
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SendSMSModal);
