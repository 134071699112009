import React from "react";
import {Container} from "reactstrap";
import TournamentForm from "../components/TournamentForm/TournamentForm";

interface IProps {

}

const CreateTournament: React.FC<IProps> = (props: IProps) => {

	return (
		<Container className="my-5 create-tournament-form">
			<TournamentForm/>
		</Container>
	)
};

export default CreateTournament;
