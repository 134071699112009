import {PrimaryTieBreakMethod} from "client";

export function tieBreakLookup(tieBreakMethod: PrimaryTieBreakMethod): string {
	switch (tieBreakMethod) {
		case PrimaryTieBreakMethod.HANDICAP:
			return "Handicap";
		case PrimaryTieBreakMethod.COUNTBACK:
			return "Countback";
		case PrimaryTieBreakMethod.USGA:
			return "USGA";
	}
}
