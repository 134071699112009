import React, {ReactNode, useState} from "react";
import {
	HoleInfo,
	Player,
	PlayerGroupings,
	TeamWithPlayers,
	TeamWithPlayersWithCalculatedHandicap,
	TournamentApi
} from "client";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	UncontrolledTooltip
} from "reactstrap";
import {FiAlertCircle, FiEdit, FiEdit2, FiUserPlus, FiX} from "react-icons/all";
import moment from "moment";
import EditPlayerCell from "./EditPlayerCell";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import AddPlayerModal from "./AddPlayerModal";
import AddTeamModal from "./AddTeamModal";
import {generateOffsetDate} from "../../utils/dateUtils";

interface IProps {
	token?: string;
	dispatch?: any;
	tournamentID: string;
	team: TeamWithPlayersWithCalculatedHandicap;
	teams: Array<TeamWithPlayersWithCalculatedHandicap>;
	holes: { [key: string]: HoleInfo; };
	playerGroupings: PlayerGroupings;

	getPlayerGroupings(): Promise<void>;
}

const TeamCard: React.FC<IProps> = (props: IProps) => {

	const {token, tournamentID, team, teams, holes} = props;
	const [showEditModal, setShowEditModal] = useState(false);
	const [showConfirmDeleteTeam, setShowConfirmDeleteTeam] = useState(false);
	const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);

	function toggleShowEditModal(): void {
		setShowEditModal(!showEditModal);
	}

	function toggleShowConfirmDeleteTeam(): void {
		setShowConfirmDeleteTeam(!showConfirmDeleteTeam);
	}

	function toggleShowAddModal(): void {
		setShowAddPlayerModal(!showAddPlayerModal);
	}

	async function handleAddedPlayer(): Promise<void> {
		setShowAddPlayerModal(false);
		await props.getPlayerGroupings();
	}

	async function confirmDeleteTeam(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			await new TournamentApi(getConfig(token)).deleteTeam({iDBody: {id: team._id}});
			setShowConfirmDeleteTeam(false);
			props.dispatch(decrementLoading());
			props.getPlayerGroupings().then().catch();
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	function mapPlayers(players: Array<Player> = []): ReactNode {
		return players.map((player: Player, i: number) => {

			return (
				<div key={`team-${team._id}-player-${i}`}>
					<EditPlayerCell
						player={player}
						getPlayerGroupings={props.getPlayerGroupings}
						tournamentID={tournamentID}
						teams={teams}
						playerGroupings={props.playerGroupings}
						isLast={(i + 1) === props?.playerGroupings.teamSize}
					/>
				</div>
			);
		});
	}

	async function finishEditingTeam(): Promise<void> {
		setShowEditModal(false);
		await props.getPlayerGroupings();
	}

	function tryToggleAddModal(): void {
		if (team.players.length >= props.playerGroupings.teamSize) {
			return;
		}

		toggleShowAddModal();
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showConfirmDeleteTeam}
				centered={true}
			>
				<ModalHeader toggle={toggleShowConfirmDeleteTeam}>Confirm Deletion of Team</ModalHeader>
				<ModalBody>
					<p>
						{`Are you sure you want to delete the "${team.name}" team? Any players currently in this team will move to being unassigned.`}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color="gray" className="mr-3" onClick={toggleShowConfirmDeleteTeam}>
						Cancel
					</Button>
					<Button color="yellow" className="text-white" onClick={confirmDeleteTeam}>
						Yes, Delete
					</Button>
				</ModalFooter>
			</Modal>

			<AddTeamModal
				isOpen={showEditModal}
				tournamentID={tournamentID}
				playerGroupings={props.playerGroupings}
				holes={holes}
				team={team}
				onClose={toggleShowEditModal}
				onDone={finishEditingTeam}
			/>

			<AddPlayerModal
				isOpen={showAddPlayerModal}
				tournamentID={tournamentID}
				teams={teams}
				defaultTeam={team}
				playerGroupings={props.playerGroupings}
				onClose={toggleShowAddModal}
				onDone={handleAddedPlayer}
			/>

			<Card className="d-flex flex-column justify-content-between">
				<div>
					<CardHeader className="d-flex flex-row align-items-center justify-content-between px-3 py-2">
						<div className="pr-1 player-team-card-name d-flex">
							<label className="mb-0 text-ellipsis">{team.name}</label>
						</div>

						<div className="px-1 d-flex justify-content-end">
							{team.players.length >= props.playerGroupings.teamSize ? (
								<span className="ml-2 text-gray" style={{opacity: 0.5}}>
									<FiUserPlus size="1.2rem" style={{maxHeight: 20}}/>
								</span>
							) : (
								<a className={"ml-2 text-light"} href="#" onClick={tryToggleAddModal}>
									<FiUserPlus size="1.2rem" style={{maxHeight: 20}}/>
								</a>
							)}

							<a className="ml-2 text-light" href="#" onClick={toggleShowEditModal}>
								<FiEdit2 size="1.2rem" style={{maxHeight: 20}}/>
							</a>

							<a className="ml-2 text-light" href="#" onClick={toggleShowConfirmDeleteTeam}>
								<FiX size="1.2rem" style={{maxHeight: 20}}/>
							</a>
						</div>
					</CardHeader>

					<div className="px-4 py-2">
						<div className="d-flex flex-wrap">
							{/*<span className="mr-3 team-card-team-info-span"><label>Start Time:</label> {moment(team.startTime).utc().format("h:mm A")}</span>*/}
							<span className="mr-3 team-card-team-info-span"><label>Start Time:</label> {moment(generateOffsetDate(props?.playerGroupings?.tournamentTimeZone, team.startTime)).format("h:mm A")}</span>

							<span className="team-card-team-info-span"><label>Start Hole:</label> {team.startHole}{team.identifier}</span>
						</div>

						{props.playerGroupings.showTeamHandicap && (
							<>
								{team.teamHandicap ? (
									<span className="team-card-team-info-span"><label>Team Handicap:</label> {team.teamHandicap}</span>
								) : (
									<>
										{team.calculatedHandicap === undefined ? (
											<>
												<span className="team-card-team-info-span"><label>Team Handicap:</label> N/A</span>
												<FiAlertCircle size="1rem" style={{marginTop: -4}} className="text-lightBlue ml-1" id={"no-team-handicap-" + team._id}/>
												<UncontrolledTooltip target={"no-team-handicap-" + team._id} placement="right">
													Team Handicap will be auto-calculated once all team players are added. Or, you can set one manually by editing this team.
												</UncontrolledTooltip>
											</>
										) : (
											<span className="team-card-team-info-span"><label>Team Handicap:</label> {team.calculatedHandicap}</span>
										)}
									</>
								)}

							</>
						)}
					</div>

					<hr className="m-0"/>

					<div style={{height: 72 * props?.playerGroupings.teamSize}}>
						{mapPlayers(team.players)}
					</div>
				</div>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TeamCard);
