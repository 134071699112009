export interface IMetaStore {
	userID?: string;
	tokenType?: string;
	owner?: string;
	token?: string;
	loadingIncrement?: number;
	errors?: Array<any>;
	sidebarVisible: boolean;
}

export default {
	loadingIncrement: 0,
	errors: [],
	sidebarVisible: false,
} as IMetaStore;
