import React from "react";
import {Input} from "reactstrap";
import moment, {Moment} from "moment";

const minutes = [];
let i: number;
for (i = 0; i < 60; i++) {
	minutes.push(i);
}

interface IProps {
	time: number;
	onChange(t: any): any;
}

const GolfTimePicker: React.FC<IProps> = (props: IProps) => {

	const {time} = props;

	function handle24Hour(_hour: number, _meridian: "AM" | "PM"): number {
		if (_meridian === "AM") {
			return _hour === 12 ? 0 : _hour;
		} else {
			return _hour === 12 ? 12 : _hour + 12;
		}
	}

	function onHourChange(e): void {
		const _h: number = handle24Hour(parseInt(e.target.value), moment(time).format("A") as "AM" | "PM");
		const _t: Moment = moment(time);

		_t.set({hour: _h});
		props.onChange(_t.valueOf());
	}

	function onMinuteChange(e): void {
		const _m: number = parseInt(e.target.value);
		const _t: Moment = moment(time);

		_t.set({minute: _m});
		props.onChange(_t.valueOf());
	}

	function onMeridianChange(e): void {
		const _meridian: "AM" | "PM" = e.target.value;
		const _t: Moment = moment(time);
		let _h: number = parseInt(moment(time).format("h"));

		if (_meridian === "AM" && _h === 12) {
			_h = 0;
		} else if (_meridian === "PM" && _h !== 12) {
			_h = _h + 12;
		}

		_t.set({hour: _h});
		props.onChange(_t.valueOf());
	}

	return (
		<div className="d-flex justify-content-start">
			<Input
				type="select"
				className="mr-2"
				style={{width: "80px"}}
				value={parseInt(moment(time).format("h"))}
				onChange={onHourChange}
			>
				<option value={12}>12</option>
				<option value={1}>1</option>
				<option value={2}>2</option>
				<option value={3}>3</option>
				<option value={4}>4</option>
				<option value={5}>5</option>
				<option value={6}>6</option>
				<option value={7}>7</option>
				<option value={8}>8</option>
				<option value={9}>9</option>
				<option value={10}>10</option>
				<option value={11}>11</option>
			</Input>

			<Input
				type="select"
				className="mr-2"
				style={{width: "80px"}}
				value={parseInt(moment(time).format("mm"))}
				onChange={onMinuteChange}
			>
				{
					minutes.map((minute: number, i: number) => {
						return (
							<option value={minute} key={`minute-option-${i}`}>
								{minute.toString().length < 2 ? ("0" + minute.toString()) : minute.toString()}
							</option>
						)
					})
				}
			</Input>

			<Input
				type="select"
				className="mr-2"
				style={{maxWidth: "80px"}}
				value={moment(time).format("A")}
				onChange={onMeridianChange}
			>
				<option value="AM">AM</option>
				<option value="PM">PM</option>
			</Input>
		</div>
	);
};

export default GolfTimePicker;
