import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialTournamentListStore, {ITournamentListStore} from "./tournamentList/InitialTournamentListStore";

export interface IStore {
	metaStore: IMetaStore;
	tournamentListStore: ITournamentListStore;
}

export default {
	metaStore: InitialMetaStore,
	tournamentListStore: InitialTournamentListStore,
} as IStore;
