import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {GolfCourse, GolfCourseApi, PlayerGroupings, TournamentApi} from "client";
import getConfig from "../utils/getConfig";
import {useQuery} from "../utils/useQuery";
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PlayerPool from "../components/players/PlayerPool";
import AddPlayerModal from "../components/players/AddPlayerModal";
import AddTeamModal from "../components/players/AddTeamModal";
import UploadPlayersFromCSVModal from "../components/modals/UploadPlayersFromCSVModal";
import {useHistory} from "react-router";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TournamentPlayers: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const [course, setCourse] = useState<GolfCourse>();
	const [playerGroupings, setPlayerGroupings] = useState<Partial<PlayerGroupings>>({teams: []});
	const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
	const [showAddTeamModal, setShowAddTeamModal] = useState(false);
	const [showCSVUploadModal, setShowCSVUploadModal] = useState(false);
	const [showAutoPairingModal, setShowAutoPairingModal] = useState(false);

	useEffect(() => {
		getPlayerGroupings().then().catch();
		getCourseDetails().then().catch();
	}, [tournamentID]);

	if (!tournamentID) {
		history.replace("/iscoregolfplus");
		return null;
	}

	function toggleAddPlayerModal(): void {
		setShowAddPlayerModal(!showAddPlayerModal);
	}

	function toggleAddTeamModal(): void {
		setShowAddTeamModal(!showAddTeamModal);
	}

	function toggleUploadCSVModal(): void {
		setShowCSVUploadModal(!showCSVUploadModal);
	}

	function toggleAutoPairingModal(): void {
		setShowAutoPairingModal(!showAutoPairingModal);
	}

	async function confirmAutoPairing(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).autoPairUnassignedPlayers({iDBody: {id: tournamentID}});
			await getPlayerGroupings();
			setShowAutoPairingModal(false);
		} catch(e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	async function getCourseDetails(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new GolfCourseApi(getConfig(props.token)).getGolfCourseForTournament({id: tournamentID});
			setCourse(res);
		} catch(e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	async function getPlayerGroupings(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new TournamentApi(getConfig(props.token)).getPlayers({tournamentID});
			setPlayerGroupings(res);
		} catch(e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<AddPlayerModal
				isOpen={showAddPlayerModal}
				tournamentID={tournamentID}
				teams={playerGroupings.teams}
				playerGroupings={playerGroupings as PlayerGroupings}
				onClose={toggleAddPlayerModal}
				onDone={getPlayerGroupings}
			/>

			<AddTeamModal
				isOpen={showAddTeamModal}
				tournamentID={tournamentID}
				playerGroupings={playerGroupings as PlayerGroupings}
				holes={course ? course.holes : {}}
				onClose={toggleAddTeamModal}
				onDone={getPlayerGroupings}
			/>

			<UploadPlayersFromCSVModal
				isOpen={showCSVUploadModal}
				tournamentID={tournamentID}
				onClose={toggleUploadCSVModal}
				onDone={getPlayerGroupings}
			/>

			<Modal
				isOpen={showAutoPairingModal}
				centered={true}
			>
				<ModalHeader toggle={toggleAutoPairingModal}>Confirm Auto Pairing</ModalHeader>

				<ModalBody>
					Are you sure you want to auto pair? This will remove any existing teams.
				</ModalBody>

				<ModalFooter>
					<Button color="gray" onClick={toggleAutoPairingModal}>
						Cancel
					</Button>

					<Button color="darkBlue" className="ml-3" onClick={confirmAutoPairing}>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>

			<Container className="my-5">
				<div className="d-flex flex-wrap justify-content-center">
					<Button color="darkBlue" onClick={toggleAddPlayerModal} className="mr-3 mb-4">Add Player</Button>
					<Button color="darkBlue" onClick={toggleUploadCSVModal} className="mr-3 mb-4">Upload from CSV</Button>
					<Button color="lightBlue" onClick={toggleAutoPairingModal} className="mr-3 mb-4">Auto Pairing</Button>
				</div>

				<div className="">
					<PlayerPool
						value={playerGroupings as PlayerGroupings}
						tournamentID={tournamentID}
						onChange={setPlayerGroupings}
						course={course}
						playerGroupings={playerGroupings as PlayerGroupings}
						getPlayerGroupings={getPlayerGroupings}
					/>
				</div>
				<div className="d-flex justify-content-center my-4">
					<Button color="darkBlue" onClick={toggleAddTeamModal}>
						Add Team
					</Button>
				</div>

				<div className="p-5"/>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentPlayers);
