import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import TournamentInformation from "./TournamentInformation";
import {
	CreateTournamentBody,
	TeeTimeStartType,
	Tournament,
	TournamentApi,
	TournamentOptions,
	TournamentOptionsLookup,
	TournamentStartType,
	TournamentType,
	UpdateTournamentBody
} from "client";
import PrimaryGameForm from "./PrimaryGameForm";
import ExtraGames from "./ExtraGames";
import SelectGolfCourse from "./SelectGolfCourse";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useHistory} from "react-router";
import {updateTournamentList} from "../../redux/tournamentList/TournamentListActions";
import {clone, fill} from "lodash";
import TournamentIFrameEntry from "./TournamentIFrameEntry";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament?: Tournament;
	onDoneUpdate?(): Promise<void>;
}

const defaultTournamentForm: Partial<CreateTournamentBody> = {
	name: "",
	startTime: "" as unknown as number,
	timeZone: "",
	isMixedGender: false,
	teamSize: 2,
	hasChat: false,
	skipOpeningVideo: false,
	allowAnytimeAccess: false,
	primaryGame: {
		tournamentType: "" as TournamentType,
		stableford: false,
		useHandicap: false,
		teamHandicapPercentages: [],
		strokePlayPlayersCounted: undefined,
		applyHandicapPercentage: undefined,
	},
	golfCourse: "",
	hasEagleCount: false,
	eagleCountMoneyPer: "" as unknown as number,
	hasParCount: false,
	parCountMoneyPer: "" as unknown as number,
	hasBirdieCount: false,
	birdieCountMoneyPer: "" as unknown as number,
	sponsorLabel: "",
	url: "",
	useIFrame: true,
	startType: TournamentStartType.TEETIME,
	teeTimeStartType: TeeTimeStartType.ONE,
};

const TournamentForm: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [tournamentTypes, setTournamentTypes] = useState<TournamentOptionsLookup>({});
	const [form, setForm] = useState<Partial<CreateTournamentBody>>(defaultTournamentForm);
	const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);

	useEffect(() => {
		getTournamentOptions().then().catch();
	}, []);

	useEffect(() => {
		if (props.tournament) {
			setForm({
				...props.tournament as unknown as Partial<CreateTournamentBody>,
				startTime: new Date(props.tournament.startTime) as unknown as number,
				teamSize: props.tournament.teamSize ? props.tournament.teamSize : 0,
				golfCourse: props.tournament.golfCourse ? props.tournament.golfCourse._id : "",
			});
		}
	}, [props.tournament]);

	/**
	 * handles case where user is editing a tournament that had a "shotgun" start type,
	 * so if the tournament info ever changes just check if there is a teeTimeStartType,
	 * and if not set it to "ONE". (will be ignored when submitting if changed back to "shotgun".
	 *
	 */
	useEffect(() => {
		if (form.teeTimeStartType === undefined) {
			setForm({
				...form,
				teeTimeStartType: TeeTimeStartType.ONE,
			});
		}
	}, [JSON.stringify(form)]);

	/**
	 * handle changes to other fields when the team size changes
	 *
	 */
	useEffect(() => {
		// check for team handicap percentages
		if (tournamentTypes && !tournamentTypes[form?.primaryGame?.tournamentType]?.teamHandicapPercentages) {
			return;
		}

		let p: number[] = clone(form.primaryGame.teamHandicapPercentages);

		if (form.teamSize !== p.length) {
			if (form.teamSize > 0) {
				const diff: number = form.teamSize - p.length;
				if (p.length < form.teamSize) {
					p = p.concat(fill(Array(diff), undefined))
				} else {
					p = p.slice(0, diff);
				}
			}

		}

		// check for the players counted field
		let strokePlayPlayersCounted: number = form.primaryGame.strokePlayPlayersCounted;
		if (form.teamSize < strokePlayPlayersCounted) {
			strokePlayPlayersCounted = form.teamSize;
		}

		setForm({
			...form,
			primaryGame: {
				...form.primaryGame,
				teamHandicapPercentages: p,
				strokePlayPlayersCounted,
			}
		});
	}, [form.teamSize]);

	async function getTournamentOptions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).getTournamentOptionsLookup();
			setTournamentTypes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function toggleShowUpdateSuccess(): void {
		setShowUpdateSuccess(!showUpdateSuccess);
	}

	function onTextChange(key: keyof Tournament): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function irregularOnChange(key: keyof Tournament, value: any): void {
		setForm({
			...form,
			[key]: value,
		});
	}

	async function saveTournament(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const currentGameTypeLookup: TournamentOptions = tournamentTypes[form.primaryGame.tournamentType];

			const reqObject: any = {
				name: form.name,
				startTime: new Date(form.startTime).valueOf(),
				timeZone: form.timeZone,
				golfCourse: form.golfCourse ? form.golfCourse : undefined,
				isMixedGender: form.isMixedGender,
				// teamSize: form.teamSize === 0 ? 1 : form.teamSize,
				teamSize: form.teamSize,
				hasBirdieCount: form.hasBirdieCount,
				birdieCountMoneyPer: form.hasBirdieCount ? form.birdieCountMoneyPer : undefined,
				hasParCount: form.hasParCount,
				parCountMoneyPer: form.hasParCount ? form.parCountMoneyPer : undefined,
				hasEagleCount: form.hasEagleCount,
				eagleCountMoneyPer: form.hasEagleCount ? form.eagleCountMoneyPer : undefined,
				hasChat: form.hasChat,
				skipOpeningVideo: form.skipOpeningVideo,
				primaryGame: {
					...form.primaryGame,
					applyHandicapPercentage: currentGameTypeLookup?.applyHandicapPercentage ? form.primaryGame.applyHandicapPercentage : undefined,
					strokePlayPlayersCounted: currentGameTypeLookup?.strokePlayPlayersCounted ? form.primaryGame.strokePlayPlayersCounted : undefined,
					teamHandicapPercentages: currentGameTypeLookup?.teamHandicapPercentages ? form.primaryGame.teamHandicapPercentages : undefined,
				},
				sponsorLabel: form.sponsorLabel ? form.sponsorLabel : undefined,
				url: form.url ? form.url : undefined,
				useIFrame: form.useIFrame,
				startType: form.startType,
				teeTimeStartType: form.startType === TournamentStartType.TEETIME ? form.teeTimeStartType : undefined,
				allowAnytimeAccess: form.allowAnytimeAccess,
			};

			console.log(reqObject);

			if (props.tournament) {
				reqObject.tournamentID = props.tournament._id;
				await new TournamentApi(getConfig(props.token)).updateTournament({updateTournamentBody: reqObject as UpdateTournamentBody});
				await props.onDoneUpdate();
				setShowUpdateSuccess(true);
			} else {
				const res = await new TournamentApi(getConfig(props.token)).createTournament({createTournamentBody: reqObject as CreateTournamentBody});

				history.push(`/existing-tournament/dashboard?tournamentID=${res}`);
			}

			props.dispatch(decrementLoading());
			props.dispatch(updateTournamentList(props.token));

		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showUpdateSuccess}
				centered={true}
				fade={true}
				toggle={toggleShowUpdateSuccess}
			>
				<ModalHeader toggle={toggleShowUpdateSuccess}>Success</ModalHeader>
				<ModalBody>
					<p>
						Tournament details have been updated successfully.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color="darkBlue" onClick={toggleShowUpdateSuccess}>
						Dismiss
					</Button>
				</ModalFooter>
			</Modal>

			<div>
				<div className="my-5">
					<TournamentInformation
						tournament={form}
						editing={props.tournament && Object.keys(props.tournament).length > 0}
						onTextChange={onTextChange}
						irregularOnChange={irregularOnChange}
					/>
				</div>

				<div className="my-5">
					<PrimaryGameForm
						tournament={form}
						onTextChange={onTextChange}
						irregularOnChange={irregularOnChange}
					/>
				</div>

				<div className="my-5">
					<SelectGolfCourse
						tournament={form}
						courseID={form.golfCourse}
						irregularOnChange={irregularOnChange}
					/>
				</div>

				<div className="my-5">
					<ExtraGames
						tournament={form}
						irregularOnChange={irregularOnChange}
					/>
				</div>

				<div className="my-5">
					<TournamentIFrameEntry
						tournament={form}
						onTextChange={onTextChange}
						irregularOnChange={irregularOnChange}
					/>
				</div>

				<div className="my-5 d-flex justify-content-end">
					<Button color="darkBlue" onClick={saveTournament}>
						Save Tournament
					</Button>
				</div>

				<div style={{height: "2rem"}}/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(TournamentForm)
