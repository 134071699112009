import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {AddNewGolfCourseBody, CreateTournamentBody, GolfCourse, GolfCourseApi, GolfCourseType, HoleInfo} from "client";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";
import GolfCourseInput, {HolesMap} from "./GolfCourseInput";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: Partial<CreateTournamentBody>;
	isOpen: boolean;
	golfCourseType: GolfCourseType;
	courseToTemplate?: string;
	onClose(): void;
	onDone(id: string): void;
}

const holeInfoDefault: { [key: string]: HoleInfo; } = {};
for (let i = 1; i <= 18; i++) {
	holeInfoDefault[i] = {
		selected: true,
		par: undefined as unknown as number,
		malePar: undefined as unknown as number,
		femalePar: undefined as unknown as number,
		handicap: undefined as unknown as number,
		maleHandicap: undefined as unknown as number,
		femaleHandicap: undefined as unknown as number,
	};
}

const defaultGolfForm: Partial<GolfCourse> = {
	courseRating: undefined as unknown as number,
	slopeRating: undefined as unknown as number,
	holes: holeInfoDefault,
	address: undefined,
	country: undefined,
	province: undefined,
	golfClub: undefined,
};

const CreateGolfCourseModal: React.FC<IProps> = (props: IProps) => {

	const {tournament, isOpen, golfCourseType} = props;
	const [form, setForm] = useState<Partial<GolfCourse>>(defaultGolfForm);

	useEffect(() => {
		if (props.courseToTemplate) {
			getTemplateCourseAndPrepareForm(props.courseToTemplate).then().catch();
		}
	}, [props.courseToTemplate]);

	function resetAndClose(): void {
		setForm(defaultGolfForm);
		props.onClose();
	}

	async function getTemplateCourseAndPrepareForm(id: string): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const res = await new GolfCourseApi(getConfig(props.token)).getGolfCourseAsTemplate({id, golfCourseType});
			setForm(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function numberOnChange(key: keyof GolfCourse): (e) => void {
		return (e) => {

			let newVal: number = e.floatValue;
			if (key === "slopeRating") {

			}

			setForm({
				...form,
				[key]: e.floatValue,
			});
		}
	}

	function textOnChange(key: keyof GolfCourse): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function holesOnChange(v: HolesMap): void {
		setForm({
			...form,
			holes: v,
		})
	}

	async function submitGolfCourse(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new GolfCourseApi(getConfig(props.token)).addNew({
				addNewGolfCourseBody: {
					...form as AddNewGolfCourseBody,
					golfCourseType,
					courseRating: tournament.isMixedGender ? undefined : form.courseRating,
					maleCourseRating: tournament.isMixedGender ? form.maleCourseRating : undefined,
					femaleCourseRating: tournament.isMixedGender ? form.femaleCourseRating : undefined,
					slopeRating: tournament.isMixedGender ? undefined : form.slopeRating,
					maleSlopeRating: tournament.isMixedGender ? form.maleSlopeRating : undefined,
					femaleSlopeRating: tournament.isMixedGender ? form.femaleSlopeRating : undefined,
				},
			});
			setForm(defaultGolfForm);
			props.dispatch(decrementLoading());
			props.onDone(res);

		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
			fade={true}
			toggle={resetAndClose}
			className="create-courses-modal"
		>
			<ModalHeader toggle={resetAndClose}>Create Golf Course</ModalHeader>

			<ModalBody>
				<Row>
					<Col xs={12} sm={6} className="mb-3">
						<Label>Golf Club</Label>
						<Input
							placeholder="Golf Club"
							value={form.golfClub}
							onChange={textOnChange("golfClub")}
							tabIndex={1}
						/>
					</Col>
					<Col xs={12} sm={6} className="mb-3">
						<Label>Address</Label>
						<Input
							placeholder="Address"
							value={form.address}
							onChange={textOnChange("address")}
							tabIndex={2}
						/>
					</Col>
					<Col xs={12} sm={6} className="mb-3">
						<Label>Country</Label>
						<Input
							placeholder="Country"
							value={form.country}
							onChange={textOnChange("country")}
							tabIndex={3}
						/>
					</Col>
					<Col xs={12} sm={6} className="mb-3">
						<Label>Province / State</Label>
						<Input
							placeholder="Province / State"
							value={form.province}
							onChange={textOnChange("province")}
							tabIndex={4}
						/>
					</Col>

					{golfCourseType === GolfCourseType.HANDICAPNONGENDERED && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Course Rating</Label>
							<NumberFormat
								placeholder="Course Rating"
								value={form.courseRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={1}
								onValueChange={numberOnChange("courseRating")}
								tabIndex={5}
							/>
						</Col>
					)}

					{(golfCourseType === GolfCourseType.HANDICAPGENDERED || golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Male Course Rating</Label>
							<NumberFormat
								placeholder="Male Course Rating"
								value={form.maleCourseRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={1}
								onValueChange={numberOnChange("maleCourseRating")}
								tabIndex={6}
							/>
						</Col>
					)}

					{(golfCourseType === GolfCourseType.HANDICAPGENDERED || golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Female Course Rating</Label>
							<NumberFormat
								placeholder="Female Course Rating"
								value={form.femaleCourseRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={1}
								onValueChange={numberOnChange("femaleCourseRating")}
								tabIndex={7}
							/>
						</Col>
					)}

					{golfCourseType === GolfCourseType.HANDICAPNONGENDERED && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Slope Rating</Label>
							<NumberFormat
								placeholder="Slope Rating"
								value={form.slopeRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={0}
								onValueChange={numberOnChange("slopeRating")}
								tabIndex={8}
							/>
						</Col>
					)}

					{(golfCourseType === GolfCourseType.HANDICAPGENDERED || golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Male Slope Rating</Label>
							<NumberFormat
								placeholder="Male Slope Rating"
								value={form.maleSlopeRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={0}
								onValueChange={numberOnChange("maleSlopeRating")}
								tabIndex={9}
							/>
						</Col>
					)}

					{(golfCourseType === GolfCourseType.HANDICAPGENDERED || golfCourseType === GolfCourseType.HANDICAPGENDEREDSCRAMBLE) && (
						<Col xs={12} sm={6} className="mb-3">
							<Label>Female Slope Rating</Label>
							<NumberFormat
								placeholder="Female Slope Rating"
								value={form.femaleSlopeRating}
								customInput={Input}
								allowLeadingZeros={false}
								allowNegative={false}
								decimalScale={0}
								onValueChange={numberOnChange("femaleSlopeRating")}
								tabIndex={10}
							/>
						</Col>
					)}
				</Row>

				<div className="mt-4 d-flex overflow-x-scroll">
					<GolfCourseInput
						golfCourseType={golfCourseType}
						holes={form.holes}
						editPar={true}
						showPar={true}
						editHandicap={true}
						showHandicap={true}
						selectable={true}
						onEditHoles={holesOnChange}
					/>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button color="darkBlue" onClick={submitGolfCourse}>
					Save
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(CreateGolfCourseModal);
