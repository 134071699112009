import React, {ChangeEventHandler, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {
	Button,
	CardBody,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {AssetApi, UploadTournamentAssetRequest} from "client";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	tournamentID: string;
	onClose(getNewData: boolean): void;
}

const defaultForm: Partial<UploadTournamentAssetRequest> = {
	asset: undefined,
	name: "",
	// description: "",
};

const AddAssetModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, tournamentID} = props;
	const [form, setForm] = useState<Partial<UploadTournamentAssetRequest>>(defaultForm);
	const inputRef = useRef<HTMLInputElement>();

	function closeHelper(): void {
		setForm(defaultForm);
		props.onClose(false);
	}

	function createOnChange(key: keyof UploadTournamentAssetRequest): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function onFileChange(e): void {
		setForm({
			...form,
			asset: e.target.files[0],
		});
	}

	function inputClick(): void {
		inputRef?.current?.click();
	}

	async function addAsset(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AssetApi(getConfig(token)).uploadTournamentAsset({
				...form,
				tournamentID,
			} as UploadTournamentAssetRequest);

			props.dispatch(decrementLoading());
			setForm(defaultForm);
			props.onClose(true);
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}

	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Add New Asset</ModalHeader>

			<ModalBody>
				<div className="mb-3">
					<Label>
						Asset*
					</Label>
					{/*<Input type="file" placeholder="Asset" onChange={onFileChange}/>*/}

					<div>
						<Button color="darkBlue" className="mr-3 mb-2" onClick={inputClick}>Choose File</Button>
						<span>{form.asset ? form.asset["name"] : "No file chosen"}</span>
						<input type="file" id="banner" onChange={onFileChange} ref={inputRef} className="d-none"/>
					</div>
				</div>

				<div className="mb-3">
					<Label>
						Asset Name
					</Label>
					<Input value={form.name} placeholder="Asset Name" onChange={createOnChange("name")}/>
				</div>

				{/*<div className="mb-3">*/}
				{/*	<Label>*/}
				{/*		Asset Description*/}
				{/*	</Label>*/}
				{/*	<Input value={form.description} placeholder="Description" onChange={createOnChange("description")}/>*/}
				{/*</div>*/}

				{/*<div className="mb-3">*/}
				{/*	<Label className="d-flex align-items-center">*/}
				{/*		Custom URL Extension <FiInfo size="1rem" style={{maxHeight: 55}} className="ml-2" id="urlExtensionTooltip"/>*/}
				{/*		<UncontrolledTooltip placement="right" target="urlExtensionTooltip">*/}
				{/*			OPTIONAL: Use this field to manually choose a label for this asset that will appear in its url. If nothing is entered, the url will be randomly generated. Only URL safe characters permitted (letters, numbers, hyphens, underscores)*/}
				{/*		</UncontrolledTooltip>*/}
				{/*	</Label>*/}
				{/*	<Input value={form.urlExtension} placeholder="URL Extension" onChange={createOnChange("urlExtension")}/>*/}
				{/*</div>*/}
			</ModalBody>

			<ModalFooter>
				<Button color="gray" onClick={closeHelper} className="mr-3">
					Cancel
				</Button>
				<Button color="lightBlue" onClick={addAsset} disabled={!form.asset}>
					Add Asset
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(AddAssetModal);
