import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Asset, AssetApi, GetPairedTournamentAssetsResponse} from "client";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useQuery} from "../utils/useQuery";
import getConfig from "../utils/getConfig";
import AssetManager from "../components/assetManager/AssetManager";
import {useHistory} from "react-router";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TournamentAssets: React.FC<IProps> = (props: IProps) => {

	const {token} = props;
	const history = useHistory();
	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");

	const [apiAssets, setApiAssets] = useState<GetPairedTournamentAssetsResponse>();
	const [addToUpdate, setAddToUpdate] = useState<keyof GetPairedTournamentAssetsResponse>(null);
	const [timedAdToUpdate, setTimedAdToUpdate] = useState<number>(null);

	useEffect(() => {
		readTournamentAssets().then().catch();
	}, [tournamentID]);

	if (!tournamentID) {
		history.replace("/iscoregolfplus");
		return null;
	}

	/**
	 * Get the tournament assets from the backend.
	 *
	 */
	async function readTournamentAssets(): Promise<void> {
		props.dispatch(decrementLoading());

		try {
			const res = await new AssetApi(getConfig(token)).getPairedTournamentAssets({tournamentID});
			setApiAssets(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	/**
	 * Call api to save change when user presses save button.
	 *
	 */
	async function saveTournamentAds(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const timedAdIDs: { [key: string]: string; } = {};

			for (const key in apiAssets.timedAds) {
				if (apiAssets.timedAds.hasOwnProperty(key)) {
					timedAdIDs[key] = apiAssets.timedAds[key]?._id;
				}
			}

			await new AssetApi(getConfig(token)).pairTournamentAssets({
				pairTournamentAssetsBody: {
					tournamentID,
					bannerID: apiAssets?.banner?._id,
					animatedLeaderboardBannerID: apiAssets?.animatedLeaderboardBanner?._id,
					scoreCardAdID: apiAssets?.scoreCardAd?._id,
					timedAdIDs,
				}
			});
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Set which ad we are going to update, which opens the modal.
	 *
	 * @param ad
	 */
	function prepareAssetManagerStandard(ad: keyof GetPairedTournamentAssetsResponse): () => void {
		return () => {
			setAddToUpdate(ad);
		}
	}

	/**
	 * Updates the apiAssets object with the result of the update ad.
	 *
	 * @param ad
	 */
	function handleStandardSelection(ad: keyof GetPairedTournamentAssetsResponse): (asset: Asset) => void {
		return (asset: Asset) => {
			setApiAssets({
				...apiAssets,
				[ad]: asset,
			});

			setAddToUpdate(null);
		}
	}

	/**
	 * Dynamically remove an asset from the apiAssets object.
	 *
	 * @param ad
	 */
	function handleStandardRemoval(ad: keyof GetPairedTournamentAssetsResponse): () => void {
		return () => {
			setApiAssets({
				...apiAssets,
				[ad]: undefined,
			});
		}
	}

	/**
	 * Dynamically select which hole we are updating for the timed ads.
	 *
	 * @param hole
	 */
	function prepareTimeHoleAdAssetManager(hole: number): () => void {
		return () => {
			setTimedAdToUpdate(hole);
		}
	}

	/**
	 * Handler for dynamically updating the timed ads.
	 *
	 * @param hole
	 */
	function handleTimedHoleSelection(hole: number): (asset: Asset) => void {
		return (asset: Asset) => {
			setApiAssets({
				...apiAssets,
				timedAds: {
					...apiAssets.timedAds,
					[hole]: asset,
				}
			});

			setTimedAdToUpdate(null);
		}
	}

	/**
	 * Handler for dynamically removing a timed ad on a specific hole.
	 *
	 * @param hole
	 */
	function handleTimedHoleRemoval(hole: number): () => void {
		return () => {
			setApiAssets({
				...apiAssets,
				timedAds: {
					...apiAssets.timedAds,
					[hole]: undefined,
				}
			});
		}
	}

	/**
	 * Create he JSX for displaying the assets for each hole's timed ad.
	 *
	 * @param holeAds
	 */
	function makeHoleDisplays(holeAds: number[]): ReactNode {

		return holeAds.map((holeAd: number, i: number) => {

			return (
				<div style={{minWidth: 280, maxWidth: 280}} className="mr-3">
					<Card>
						<CardBody>
							<h5 className="text-nowrap mb-0">
								{`Hole ${holeAd}`}
							</h5>
							<hr className="mt-1"/>

							<div style={{height: 280}}>
								{apiAssets?.timedAds[holeAd] ? (
									<div className="w-100 h-100 d-flex align-items-center">
										<img
											className="w-100"
											style={{objectFit: "contain", maxWidth: "100%", maxHeight: "100%"}}
											src={apiAssets?.timedAds[holeAd].url}
										/>
									</div>
								) : (
									<div className="w-100 h-100 d-flex justify-content-center align-items-center">
										<span className="font-italic">No Ad chosen yet.</span>
									</div>
								)}
							</div>

							<div>
								{/*{apiAssets?.timedAds[holeAd] && (*/}
									<div className="d-flex justify-content-center mt-3" style={{opacity: apiAssets?.timedAds[holeAd] ? 1 : 0}}>
										<Button color="yellow" className="text-white w-100" onClick={apiAssets?.timedAds[holeAd] ? handleTimedHoleRemoval(holeAd) : () => {}} disabled={!apiAssets?.timedAds[holeAd]}>Remove Hole Ad</Button>
									</div>
								{/*)}*/}

								<div className="d-flex justify-content-center mt-3">
									<Button color="lightBlue" className="w-100" onClick={prepareTimeHoleAdAssetManager(holeAd)}>{apiAssets?.timedAds[holeAd] ? "Change Asset" : "Choose Asset"}</Button>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>

			)
		})
	}

	return (
		<React.Fragment>
			<AssetManager
				isOpen={addToUpdate !== null}
				allowSelect={true}
				tournamentID={tournamentID}
				onClose={prepareAssetManagerStandard(null)}
				onSelect={handleStandardSelection(addToUpdate)}
			/>

			<AssetManager
				isOpen={timedAdToUpdate !== null}
				allowSelect={true}
				tournamentID={tournamentID}
				onClose={prepareTimeHoleAdAssetManager(null)}
				onSelect={handleTimedHoleSelection(timedAdToUpdate)}
			/>

			<Container className="my-5">

				<Card>
					<CardBody>
						<h1>Tournament Asset Manager</h1>
						<h6 className="text-info text-center">
							Having an adblocker browser plugin may prevent this page from working properly - please disable for this domain if you are using one and then refresh the page.
						</h6>
						<p>
							Here you can upload or remove images for the various banners & advertisements that players
							will see while using the platform. Remember to click the "Save All Changes" button when you're finished.
						</p>

						<hr/>

						<div>
							<h4>Main Banner Ad</h4>
							<p>This is shown at the top, across all pages of the player portal. (Suggested size: 320px x 60px)</p>

							<div className="d-flex justify-content-center">
								{apiAssets?.banner ? (
									<div className="d-flex justify-content-center align-items-start">
										<img
											src={apiAssets?.banner?.url}
											style={{maxWidth: "50%", objectFit: "contain"}}
										/>
									</div>
								) : (
									<span className="font-italic">No Asset Chosen</span>
								)}
							</div>

							{apiAssets?.banner && (
								<div className="d-flex justify-content-center mt-3">
									<Button color="yellow" className="text-white" onClick={handleStandardRemoval("banner")}>Remove Banner Ad</Button>
								</div>
							)}

							<div className="d-flex justify-content-center mt-3">
								<Button color="lightBlue" onClick={prepareAssetManagerStandard("banner")}>{apiAssets?.banner ? "Change Main Banner" : "Choose a Main Banner"}</Button>
							</div>
						</div>

						<hr/>

						<div>
							<h4>Auto-Scrolling Leaderboard Banner</h4>
							<p>This is shown at the top of the "auto-scrolling" leaderboard page, found by going to "Dashboard", scrolling down to "Leaderboard", and then clicking the "Pop Out" button. (Suggested size: 320px x 100px)</p>

							<div className="d-flex justify-content-center">
								{apiAssets?.animatedLeaderboardBanner ? (
									<div className="d-flex justify-content-center align-items-start">
										<img
											src={apiAssets?.animatedLeaderboardBanner?.url}
											style={{maxWidth: "50%", objectFit: "contain"}}
										/>
									</div>
								) : (
									<span className="font-italic">No Asset Chosen</span>
								)}
							</div>

							{apiAssets?.animatedLeaderboardBanner && (
								<div className="d-flex justify-content-center mt-3">
									<Button color="yellow" className="text-white" onClick={handleStandardRemoval("animatedLeaderboardBanner")}>Remove Auto-Scrolling Leaderboard Banner</Button>
								</div>
							)}

							<div className="d-flex justify-content-center mt-3">
								<Button color="lightBlue" onClick={prepareAssetManagerStandard("animatedLeaderboardBanner")}>{apiAssets?.animatedLeaderboardBanner ? "Change Banner" : "Choose a Banner"}</Button>
							</div>
						</div>

						<hr/>

						<div>
							<h4>Timed Hole Ads</h4>
							<p>This are shown to the player as a pop-up once they have entered all the scores on a given hole on the score card page. You can choose one ad to show per hole, or none at all. (Suggested size: 750px x 1334px)</p>

							{apiAssets?.timedAds && (
								<div className="w-100 d-flex overflow-x-scroll p-3">
									{makeHoleDisplays(apiAssets.selectedHoles)}
								</div>
							)}
						</div>

						<hr/>

						<div>
							<h4>Score Card Ad</h4>
							<p>This is shown at bottom of the scare card page. (Suggested size: 320px x 60px)</p>

							<div className="d-flex justify-content-center">
								{apiAssets?.scoreCardAd ? (
									<div className="d-flex justify-content-center align-items-start">
										<img
											src={apiAssets?.scoreCardAd.url}
											style={{maxWidth: "50%", objectFit: "contain"}}
										/>
									</div>
								) : (
									<span className="font-italic">No Asset Chosen</span>
								)}
							</div>

							{apiAssets?.scoreCardAd && (
								<div className="d-flex justify-content-center mt-3">
									<Button color="yellow" className="text-white" onClick={handleStandardRemoval("scoreCardAd")}>Remove Score Card Ad</Button>
								</div>
							)}
							<div className="d-flex justify-content-center mt-3">
								<Button color="lightBlue" onClick={prepareAssetManagerStandard("scoreCardAd")}>{apiAssets?.scoreCardAd ? "Change Score Card Ad" : "Choose a Score Card Ad"}</Button>
							</div>
						</div>

						<hr/>

						<div className="d-flex justify-content-end">
							<Button color="darkBlue" onClick={saveTournamentAds}>
								Save All Changes
							</Button>
						</div>
					</CardBody>
				</Card>
			</Container>

			<div className="p-5"/>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentAssets);
