import React, {useEffect, useState} from "react";
import {CreateTournamentBody, GolfCourse, GolfCourseApi, Tournament} from "client";
import {Button, Card, CardBody, CardHeader, Spinner} from "reactstrap";
import BrowseCoursesModal from "../golfCourse/BrowseCoursesModal";
import GolfCourseView from "../golfCourse/GolfCourseView";
import {isNil} from "lodash";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {isCourseGendered} from "../../utils/isCourseGendered";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: Partial<CreateTournamentBody>;
	courseID: string;

	irregularOnChange(key: keyof CreateTournamentBody, value: any): void;
}

const SelectGolfCourse: React.FC<IProps> = (props: IProps) => {

	const {tournament} = props;
	const [showSelectionModal, setShowSelectionModal] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);
	const [course, setCourse] = useState<GolfCourse>(null);

	useEffect(() => {
		if (props.courseID && props.courseID.length > 0) {
			getGolfCourse(props.courseID).then().catch();
		}
	}, [props.courseID]);

	async function getGolfCourse(id: string): Promise<void> {
		setLocalLoading(true);
		try {
			const res = await new GolfCourseApi(getConfig(props.token)).getGolfCourse({id});
			setCourse(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
		setLocalLoading(false);
	}

	function toggleModal(): void {
		setShowSelectionModal(!showSelectionModal);
	}

	function handleSelect(id: string): void {
		setShowSelectionModal(false);
		props.irregularOnChange("golfCourse", id);
	}

	return (
		<React.Fragment>
			<BrowseCoursesModal
				tournament={tournament}
				isOpen={showSelectionModal}
				onClose={toggleModal}
				onSelect={handleSelect}
			/>

			<Card>
				<CardHeader>Select Golf Course</CardHeader>
				<CardBody>
					{props.courseID ? (
						<React.Fragment>
							{localLoading ? (
								<div className="d-flex justify-content-center m-4">
									<Spinner/>
								</div>
							) : (
								<>
									{course && (
										<>
											<div className="d-flex justify-content-center mb-4">
												<Button color="lightBlue" onClick={toggleModal}>
													Choose a different course
												</Button>
											</div>
											<hr/>

											<GolfCourseView golfCourse={course}/>
										</>
									)}
								</>
							)}
						</React.Fragment>
					) : (
						<div className="d-flex flex-column align-items-center">
							<h5 className="mb-4">
								No course selected yet.
							</h5>
							<Button color="lightBlue" onClick={toggleModal}>
								Select a Golf Course...
							</Button>
						</div>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SelectGolfCourse);
