import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useQuery} from "../utils/useQuery";
import {AverageScore, Tournament, TournamentApi, TournamentSummary} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import TournamentLogs from "../components/tournamentDashboard/TournamentLogs";
import TournamentChatDisplayForTOs from "../components/tournamentDashboard/TournamentChatDisplayForTOs";
import UserDeleteTournamentHandler from "../components/UserDeleteTournamentHandler";
import GolfCourseInput, {defaultHoleNumbers} from "../components/golfCourse/GolfCourseInput";
import moment from "moment";
import GolfCourseScoresDisplay from "../components/golfCourse/GolfCourseScoresDisplay";
import ConfirmResetTournamentScoresModal from "../components/modals/ConfirmResetTournamentScoresModal";
import {useHistory} from "react-router";
import {generateOffsetDate} from "../utils/dateUtils";
import TournamentDashboardTeamListItem from "../components/TournamentDashboardTeamListItem";

interface IProps {
	token?: string;
	dispatch?: any;
}

const TournamentDashboard: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const query = useQuery();
	const tournamentID: string = query.get("tournamentID");
	const [summaryRes, setSummaryRes] = useState<TournamentSummary>();
	const [iFrameDisplay, setIFrameDisplay] = useState("none");
	const [forceChildrenToUpdateKey, setForceChildrenToUpdateKey] = useState(1);
	const [showConfirmReset, setShowConfirmReset] = useState(false);

	useEffect(() => {
		readTournamentSummary().then().catch();
	}, [tournamentID]);

	if (!tournamentID) {
		history.replace("/iscoregolfplus");
		return null;
	}

	async function readTournamentSummary(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(props.token)).getTournamentSummary({tournamentID});
			setSummaryRes(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function enableIFrame(): void {
		setTimeout(() => {
			setIFrameDisplay("block");
		}, 500);
	}

	async function onDoneAction(): Promise<void> {
		setForceChildrenToUpdateKey(forceChildrenToUpdateKey + 1);
		readTournamentSummary().then().catch();
	}

	function toggleShowConfirmReset(): void {
		setShowConfirmReset(!showConfirmReset);
	}

	function getAverageScores(): { scores: string[], activeHoles: boolean[] } {
		const _averageScores: { [key: string]: AverageScore } = {};

		defaultHoleNumbers.forEach((hole: number) => {
			_averageScores[hole.toString()] = undefined;

			if (summaryRes.averageHoleScores[hole.toString()]) {
				_averageScores[hole.toString()] = summaryRes.averageHoleScores[hole.toString()];
			}
		});

		const _activeHoles: boolean[] = [];

		const _scores: string[] = Object.entries(_averageScores).sort((a: [string, AverageScore], b: [string, AverageScore]) => {
			return parseInt(a[0]) - parseInt(b[0]);
		}).map((averageScore: [string, AverageScore]) => {
			if (averageScore[1]) {
				_activeHoles[parseInt(averageScore[0])] = true;
				return averageScore[1]?.gross.toString() + "/" + averageScore[1]?.net.toString();
			}

			_activeHoles[parseInt(averageScore[0])] = false;
			return "-/-"
		});

		return {
			scores: _scores,
			activeHoles: _activeHoles,
		};
	}

	return (
		<React.Fragment>
			<ConfirmResetTournamentScoresModal
				isOpen={showConfirmReset}
				tournamentID={tournamentID}
				onClose={toggleShowConfirmReset}
				onDone={onDoneAction}
			/>

			<Container className="my-5 overflow-x-scroll to-dashboard">
				<h1 className="mb-3">Tournament Dashboard</h1>

				{(summaryRes && Object.keys(summaryRes).length > 0) && (
					<React.Fragment>
						<Card className="my-5">
							<CardHeader>General Tournament Information</CardHeader>
							<CardBody>
								<div className="mb-3">
									<label className="text-lightBlue">Tournament Name</label>
									<h3 className="font-weight-light">{summaryRes.name}</h3>
								</div>
								<div className="mb-3">
									<label className="text-lightBlue">Primary Game Format</label>
									<h3 className="font-weight-light">{summaryRes.formatName}</h3>
								</div>
								<div className="mb-3">
									<label className="text-lightBlue">Start Time</label>
									<h3 className="font-weight-light">{moment(new Date(generateOffsetDate(summaryRes.timeZone, summaryRes.startTime))).format("MMMM Do YYYY h:mm A")}</h3>
								</div>
								<div className="mb-3">
									<label className="text-lightBlue">Time Zone</label>
									<h3 className="font-weight-light">{summaryRes.timeZone}</h3>
								</div>
								<div className="mb-3">
									<label className="text-lightBlue">Tournament Status</label>
									<h3 className="font-weight-light">{summaryRes.tournamentStatus}</h3>
								</div>
							</CardBody>
						</Card>

						<Card className="my-5">
							<CardHeader>Players & Teams</CardHeader>
							<CardBody>
								<div>
									<h4>
										{`${summaryRes.numberOfTeams} Team${summaryRes.numberOfTeams === 1 ? "" : "s"}, ${summaryRes.numberOfPlayers} Player${summaryRes.numberOfPlayers === 1 ? "" : "s"}`}
									</h4>
								</div>

								<hr/>

								{summaryRes.topTeams.map((t, i) => {
									return (
										<div key={`team-display-${i}`} className="mb-2">
											<TournamentDashboardTeamListItem
												index={i}
												team={t}
												onDone={readTournamentSummary}
											/>
										</div>
									);
								})}
							</CardBody>
						</Card>

						<Card>
							<CardHeader>Average Scores</CardHeader>
							<CardBody>
								<div className="d-flex justify-content-center mb-3">
									<h4 className="font-weight-normal">{`Average Score: ${summaryRes.averageScore.gross}/${summaryRes.averageScore.net}`}</h4>
								</div>

								<div className="overflow-x-scroll">
									<GolfCourseScoresDisplay
										activeHoles={getAverageScores().activeHoles}
										customEntries={[
											{
												title: "Gross",
												values: Object.entries(summaryRes.averageHoleScores).map((averageScore: [string, AverageScore]) => {
													return averageScore[1].gross.toString();
												}),
											},
											{
												title: "Net",
												values: Object.entries(summaryRes.averageHoleScores).map((averageScore: [string, AverageScore]) => {
													return averageScore[1].net.toString();
												}),
											},
											// {
											// 	title: "Gross/Net",
											// 	values: getAverageScores().scores,
											// }
										]}
									/>
								</div>

								{/*<div className="d-flex justify-content-center my-3">*/}
								{/*	<Button color="yellow" className="text-white" onClick={toggleShowConfirmReset}>*/}
								{/*		Reset Scores*/}
								{/*	</Button>*/}
								{/*</div>*/}
							</CardBody>
						</Card>

						<Card className="my-5">
							<CardHeader>Golf Course</CardHeader>
							<CardBody>
								<div className="overflow-x-scroll">
									<GolfCourseInput
										golfCourseType={summaryRes.golfCourse.golfCourseType}
										holes={summaryRes.golfCourse.holes}
										editPar={false}
										showPar={true}
										editHandicap={false}
										showHandicap={true}
										selectable={false}
									/>
								</div>
							</CardBody>
						</Card>
					</React.Fragment>
				)}

				<Row className="my-5">
					<Col md={12} lg={6} className="mb-5 mb-lg-0" style={{height: 600}}>
						<h3>Tournament Chat</h3>
						<TournamentChatDisplayForTOs tournamentID={tournamentID}/>
					</Col>

					<Col md={12} lg={6} className="mt-5 mt-lg-0" style={{height: 600}}>
						<h3>Tournament Logs</h3>
						<TournamentLogs tournamentID={tournamentID}
										forceChildrenToUpdateKey={forceChildrenToUpdateKey}/>
					</Col>
				</Row>

				<div style={{height: "1rem"}}/>

				<div className="my-5">
					<h3>Leaderboard</h3>
					<Card>
						<iframe
							src={process.env.REACT_APP_PLAYER_PORTAL_URL + `/leader-board-view?tournamentID=${tournamentID}&animationDisabled=true`}
							className="w-100 border-0"
							style={{minHeight: 800, display: iFrameDisplay}}
							onLoad={enableIFrame}
						/>
					</Card>
				</div>

				<div className="d-flex justify-content-end mb-5">
					<a href={process.env.REACT_APP_PLAYER_PORTAL_URL + `/leader-board-view?tournamentID=${tournamentID}`}
					   target="_blank" rel="noreferrer noopener">
						<Button color="lightBlue">
							Pop Out
						</Button>
					</a>
				</div>

				<div className="mt-5 mb-3">
					<h3>Pairings</h3>
					<Card>
						<iframe
							src={process.env.REACT_APP_PLAYER_PORTAL_URL + `/team-start-details-view?tournamentID=${tournamentID}&animationDisabled=true`}
							className="w-100 border-0"
							style={{minHeight: 800, display: iFrameDisplay}}
							onLoad={enableIFrame}
						/>
					</Card>
				</div>

				<div className="d-flex justify-content-end mb-5">
					<a href={process.env.REACT_APP_PLAYER_PORTAL_URL + `/team-start-details-view?tournamentID=${tournamentID}`}
					   target="_blank" rel="noreferrer noopener">
						<Button color="lightBlue">
							Pop Out
						</Button>
					</a>
				</div>

				<div className="my-5">
					<UserDeleteTournamentHandler tournamentID={tournamentID}/>
				</div>
			</Container>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentDashboard);
