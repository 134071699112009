import React from "react";
import {Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import {CreateTournamentBody} from "client";

interface IProps {
	tournament: Partial<CreateTournamentBody>;
	onTextChange(key: keyof CreateTournamentBody): (e) => void;
	irregularOnChange(key: string, value: any): void;
}

const TournamentIFrameEntry: React.FC<IProps> = (props: IProps) => {

	const {tournament} = props;

	function toggleUseIframe(): void {
		props.irregularOnChange("useIFrame", !tournament?.useIFrame)
	}

	return (
		<Card>
			<CardHeader>Sponsor URL</CardHeader>
			<CardBody>
				<Label>Enter sponsor label (Optional)</Label>
				<Input
					placeholder="Sponsor Label"
					value={tournament.sponsorLabel}
					onChange={props.onTextChange("sponsorLabel")}
					className="mb-3"
				/>

				<Label>Enter sponsor URL (Optional)</Label>
				<Input
					placeholder="Sponsor URL"
					value={tournament.url}
					onChange={props.onTextChange("url")}
				/>
				<p className="text-secondary">
					(Must include "https://" at the start - "http://" will not work.)<br/>
					Additionally, some websites may not work - this is out of the control of iScoreGolf Plus.
				</p>

				<div className="mb-3">
					<Label>Sponsor Display Method</Label>
					<p className="mb-0">
						<input
							type="checkbox"
							name="useIframe"
							id="useIframe"
							checked={tournament.useIFrame === false}
							onChange={toggleUseIframe}
						/>
						<label htmlFor="useIframe">Open sponsor website in new browser tab.</label>
					</p>
					<p className="text-secondary">
						- Leave unchecked to show the sponsor's website "inside" of the Player Portal by selecting the Sponsor tab from the navigation bar.
						<br/>
						- Outside the control of iScoreGolf Plus, some websites prevent this behaviour from working properly.
						<br/>
						- In these scenarios, check this option to make the Sponsor's website open in a new tab instead.
					</p>
				</div>
			</CardBody>
		</Card>
	);
};

export default TournamentIFrameEntry;
