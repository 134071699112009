import React, {ReactNode, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {GolfCourse, Player, PlayerGroupings, TeamWithPlayers, TeamWithPlayersWithCalculatedHandicap} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import EditPlayerCell from "./EditPlayerCell";
import TeamCard from "./TeamCard";

interface IProps {
	token?: string;
	dispatch?: any;
	value: PlayerGroupings;
	tournamentID: string;
	course: GolfCourse;
	playerGroupings: PlayerGroupings;

	onChange(v: PlayerGroupings): void;
	getPlayerGroupings(): Promise<void>;
}

const PlayerPool: React.FC<IProps> = (props: IProps) => {

	const {value, tournamentID, course} = props;

	function mapUnassignedPlayers(players: Array<Player> = []): ReactNode {
		return players.map((player: Player, i: number) => {
			return (
				<div key={`unassigned-player_${i}`} className="teams-col">
					<Card className="h-100">
						<EditPlayerCell player={player} getPlayerGroupings={props.getPlayerGroupings} tournamentID={tournamentID} teams={value.teams} playerGroupings={props.playerGroupings} isLast={true}/>
					</Card>
				</div>
			);
		});
	}

	function mapTeams(teams: Array<TeamWithPlayersWithCalculatedHandicap> = []): ReactNode {
		return teams.map((team: TeamWithPlayersWithCalculatedHandicap, i: number) => {
			return (
				<div key={`team_${i}`} className="teams-col">
					<TeamCard
						tournamentID={tournamentID}
						team={team}
						teams={teams}
						getPlayerGroupings={props.getPlayerGroupings}
						holes={course ? course.holes : {}}
						playerGroupings={props.playerGroupings}
					/>
				</div>
			);
		});
	}

	return (
		<React.Fragment>

			<div className="players-page-teams-layout-manager">
				<Card>
					<CardHeader>
						Unassigned Players
					</CardHeader>
					<CardBody>
						{(value.unassignedPlayers && value.unassignedPlayers.length > 0) ? (
							<div className="teams-row">
								{mapUnassignedPlayers(value.unassignedPlayers)}
							</div>
						) : (
							<div className="p-5">
								<p className="text-center font-italic mb-0">No unassigned players. Click "Add Player" to create one.</p>
							</div>
						)}
					</CardBody>
				</Card>
			</div>

			<div className="players-page-teams-layout-manager">
				<Card className="mt-4">
					<CardHeader>
						Teams
					</CardHeader>
					<CardBody>
							{(value.teams && value.teams.length > 0) ? (
								<div className="teams-row">
									{mapTeams(value.teams)}
								</div>
							) : (
								<div className="p-5">
									<p className="text-center font-italic mb-0">
										No Teams. Click "Add Team" to create one.
									</p>
								</div>
							)}
					</CardBody>
				</Card>
			</div>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PlayerPool);
