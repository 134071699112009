import InitialTournamentListStore, {ITournamentListStore} from "./InitialTournamentListStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";

export enum TournamentListAction {
	UPDATE = "UPDATE",
}

export default function(store: ITournamentListStore = InitialTournamentListStore, a: IAction<TournamentListAction, any>): ITournamentListStore {

	const n: ITournamentListStore = cloneDeep(store);

	switch(a.type) {
		case TournamentListAction.UPDATE:
			n.tournaments = a.payload;
			break;
	}

	return n;
}
