import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SelectOptionsFactory, {ISelectOption} from "../forms/SelectOptionsFactory";
import {
	AddTeamBody,
	EditTeamBody,
	HoleInfo,
	PlayerGroupings,
	TeamWithPlayers,
	TeeTimeStartType,
	TournamentApi,
	TournamentStartType
} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";
import moment from "moment";
import {getMillisecondsFromStartOfDay} from "../../utils/getMillisecondsFromStartOfDay";
import DatePicker from "react-datepicker";
import {handleDateChangeRaw} from "../../utils/handleDateChangeRaw";
import alphabetIdentifiers from "../../utils/alphabetIdentifiers";
import TimePicker from "react-time-picker";
import GolfTimePicker from "../forms/GolfTimePicker";
import {convertOffsetDate, generateOffsetDate} from "../../utils/dateUtils";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	tournamentID: string;
	playerGroupings: PlayerGroupings;
	holes: { [key: string]: HoleInfo; }
	team?: TeamWithPlayers;
	onClose(): void;
	onDone(): Promise<void>;
}

const defaultAddTeamForm: Partial<TeamWithPlayers> = {
	name: "",
	startTime: "" as any,
	startHole: "" as unknown as number,
	identifier: "",
	teamHandicap: "" as unknown as number,
};

const AddTeamModal: React.FC<IProps> = (props:IProps) => {

	const {token, isOpen, tournamentID, holes, team} = props;
	const [form, setForm] = useState<Partial<TeamWithPlayers>>(defaultAddTeamForm);

	useEffect(() => {

		if (isOpen && props.team) {
			setForm({
				name: team.name,
				startTime: team.startTime,
				startHole: team.startHole,
				identifier: team.identifier,
				teamHandicap: team.teamHandicap,
			});
		} else if (isOpen) {
			setForm({
				...form,
				startTime: props?.playerGroupings?.tournamentStartTime,
			});
		}
	}, [isOpen]);

	function resetAndClose(): void {
		setForm(defaultAddTeamForm);
		props.onClose();
	}

	function onChange(key: keyof TeamWithPlayers): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			})
		}
	}

	function onTimeChange(t): void {
		setForm({
			...form,
			// startTime: t,
			startTime: convertOffsetDate(props?.playerGroupings?.tournamentTimeZone, t),
		})
	}

	function onTeamHandicapChange(e): void {
		setForm({
			...form,
			teamHandicap: e.floatValue,
		})
	}

	async function saveTeam(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			const addTeamBody: Partial<AddTeamBody> = {
				tournamentID,
				name: form.name,
				// startTime: getMillisecondsFromStartOfDay(moment(form.startTime)),
				// startTime: moment(form.startTime).valueOf(),
				startTime: new Date(form.startTime).valueOf(),
				startHole: form.startHole as unknown as string === "" ? undefined : form.startHole,
				identifier: props?.playerGroupings?.tournamentStartType === TournamentStartType.TEETIME ? undefined : (form.identifier === "" ? undefined : form.identifier),
			};

			if (form.teamHandicap) {addTeamBody.teamHandicap = form.teamHandicap}

			if (team && team._id) {
				await new TournamentApi(getConfig(token)).editTeam({editTeamBody: {...addTeamBody, teamID: team._id} as EditTeamBody});
			} else {
				await new TournamentApi(getConfig(token)).addTeam({addTeamBody: addTeamBody as AddTeamBody});
			}

			props.dispatch(decrementLoading());
			resetAndClose();
			props.onDone().then().catch();
		} catch(e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
			toggle={resetAndClose}
		>
			<ModalHeader toggle={resetAndClose}>{team ? "Edit Team" : "Add Team"}</ModalHeader>

			<ModalBody>
				<div className="mb-3">
					<Label>Team Name</Label>
					<Input placeholder="Team Name" value={form.name}
					       onChange={onChange("name")}/>
				</div>

				<div className="mb-3">
					<Label>Start Time</Label>
					{/*<div className="react-date-picker-helper">*/}
					{/*	<DatePicker*/}
					{/*		placeholderText="Select Time"*/}
					{/*		selected={form.startTime}*/}
					{/*		onChange={onTimeChange}*/}
					{/*		showTimeSelect={true}*/}
					{/*		showTimeSelectOnly={true}*/}
					{/*		customInput={<Input/>}*/}
					{/*		onChangeRaw={handleDateChangeRaw}*/}
					{/*		timeIntervals={5}*/}
					{/*		timeCaption="Start Time"*/}
					{/*		dateFormat="h:mm aa"*/}
					{/*	/>*/}
					{/*</div>*/}

					<GolfTimePicker time={generateOffsetDate(props?.playerGroupings?.tournamentTimeZone, form.startTime)} onChange={onTimeChange}/>
				</div>

				<div className="mb-3">
					<Label>Start Hole</Label>
					<Input type="select" placeholder="Tournament Description" value={form.startHole} onChange={onChange("startHole")}>
						<option selected disabled value="">Select Start Hole</option>
						<hr/>
						{props?.playerGroupings?.tournamentStartType === TournamentStartType.TEETIME ? (
							<React.Fragment>
								{props?.playerGroupings?.teeTimeStartType === TeeTimeStartType.ONEANDTEN ? (
									<>
										<option value={1}>1</option>
										<option value={10}>10</option>
									</>
								) : (
									<React.Fragment>
										{props?.playerGroupings?.teeTimeStartType === TeeTimeStartType.ONE ? (
											<option value={1}>1</option>
										) : (
											<option value={10}>10</option>
										)}
									</React.Fragment>
								)}
							</React.Fragment>
						) : (
							<SelectOptionsFactory
								options={Object.keys(holes).filter((holeNumber: string, i: number) => {
									return holes[holeNumber].selected
								}).map((hole): ISelectOption => {
									return {
										value: parseInt(hole),
										label: hole,
									}
								})}
							/>
						)}
					</Input>
				</div>

				{props?.playerGroupings?.tournamentStartType !== TournamentStartType.TEETIME && (
					<div className="mb-3">
						<Label>Identifier</Label>
						<Input type="select" placeholder="Team Identifier" value={form.identifier} onChange={onChange("identifier")}>
							<option selected disabled value="">Select Identifier</option>
							<hr/>
							<SelectOptionsFactory strings={alphabetIdentifiers}/>
						</Input>
					</div>
				)}

				{props.playerGroupings.showTeamHandicap && (
					<div className="mb-3">
						<Label>Team Handicap <span className="text-secondary font-italic">(Leave empty to have this auto-calculated based on the player handicaps.)</span></Label>
						<NumberFormat
							placeholder="Team Handicap"
							value={form.teamHandicap}
							customInput={Input}
							allowLeadingZeros={false}
							allowNegative={false}
							decimalScale={0}
							onValueChange={onTeamHandicapChange}
						/>
					</div>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color="darkBlue" onClick={saveTeam}>
					Save Team
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(AddTeamModal);
