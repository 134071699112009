import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Card} from "reactstrap";
import MessageList from "./MessageList";
import ChatInput from "./ChatInput";
import {ChatApi} from "client";
import getConfig from "../../utils/getConfig";
import {addError} from "../../redux/meta/MetaActions";
import * as Scroll from 'react-scroll';

interface IProps {
	token?: string;
	dispatch?: any;
	tournamentID: string;
}

const TournamentChatDisplayForTOs: React.FC<IProps> = (props: IProps) => {

	const [messages, setMessages] = useState([]);
	const [initialLoad, setInitialLoad] = useState(true);
	const scrollRef: any = useRef();

	useEffect(() => {

		readChat().then().catch();

		const interval = setInterval(() => {readChat().then().catch()}, 5000);
		return () => clearInterval(interval);

	}, []);

	useEffect(() => {
		if (initialLoad && messages.length > 0) {
			// scrollRef.current.scrollIntoView();
			scrollToBottom();
			setInitialLoad(false);
		} else {
			scrollToBottom();
			// scrollRef.current.scrollIntoView({behavior: "smooth"});
			scrollRef.current.scrollTo();
		}
	}, [messages.length]);

	async function readChat(): Promise<void> {
		try {
			const res = await new ChatApi(getConfig(props.token)).userGetMessages({tournamentID: props.tournamentID});
			setMessages(res);
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	async function sendMessage(m: string): Promise<void> {
		try {
			await new ChatApi(getConfig(props.token)).userPostMessage({
				userPostMessageBody: {
					tournamentID: props.tournamentID,
					message: m,
				},
			});

			readChat().then().catch();
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	function scrollToBottom(): void {
		Scroll.animateScroll.scrollToBottom({containerId: "chat-scroll-container"});
	}

	return (
		<Card className="h-100 overflow-hidden">
			<div className="position-relative h-100">
				<div className="h-100 overflow-y-scroll" id="chat-scroll-container">
					<MessageList messages={messages} onDelete={readChat}/>
					<div style={{height: 55}}/>
					<div ref={scrollRef}/>
				</div>
				<ChatInput onSend={sendMessage}/>
			</div>
		</Card>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentChatDisplayForTOs);
