import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Card, CardBody, Container, Input, Label} from "reactstrap";
import {addError, decrementLoading, incrementLoading, login} from "../redux/meta/MetaActions";
import {LoginBody, UserApi} from "client";
import getConfig from "../utils/getConfig";
import {NavLink, useHistory} from "react-router-dom";
import {IStore} from "../redux/defaultStore";

interface IProps {
	token?: string;
	dispatch?: any;
}

const defaultLoginForm: LoginBody = {
	email: "",
	password: "",
};

const Login: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const [form, setForm] = useState<LoginBody>(defaultLoginForm);

	useEffect(() => {
		if (props.token) {
			history.replace("/iscoregolfplus");
		}
	}, []);

	function createOnChange(key: keyof LoginBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * submit log in form and dispatch login action + navigate to dashboard on success
	 *
	 * @param e
	 */
	async function submitLogin(e): Promise<void> {
		if (e) {
			e.preventDefault()
		}

		props.dispatch(incrementLoading());

		try {
			const res = await new UserApi(getConfig()).userLogin({loginBody: form});

			props.dispatch(decrementLoading());
			props.dispatch(login(res));
			history.push("/iscoregolfplus");
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<div className="vh-100 vw-100 overflow-y-scroll">
			<div className="w-100 h-100 login-background position-absolute"/>
			<Container className="vh-100 d-flex justify-content-center">
				<div className="login-form-card">
					<div className="login-form-card_mobile-spacer"/>

					<Card className="w-100">
						<CardBody className="p-4">
							<h4 className="mb-3 text-center">
								iScoreGolf Plus Tournament Organizer Login
							</h4>

							<form>
								<div className="mb-3">
									<Label>Email</Label>
									<Input placeholder="Email Address" value={form.email}
									       onChange={createOnChange("email")}/>
								</div>

								<div className="mb-3">
									<Label>Password</Label>
									<Input type="password" placeholder="Password" value={form.password}
									       onChange={createOnChange("password")}/>
								</div>

								<div className="d-flex justify-content-end">
									<Button color="darkBlue" type="submit" onClick={submitLogin}>
										Log In
									</Button>
								</div>

								<p className="mb-0 mt-3 text-center">
									No account? <NavLink to="/register" className="text-lightBlue">Register Instead</NavLink>
								</p>
							</form>
						</CardBody>
					</Card>

					<div className="login-form-card_mobile-spacer"/>
				</div>
			</Container>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(Login);
