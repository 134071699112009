import {LeaderboardType} from "client";

export function leaderboardTypeLookup(leaderboardType: LeaderboardType): string {
    switch (leaderboardType) {
        case LeaderboardType.TeamGross:
            return "Team Gross";
        case LeaderboardType.TeamNet:
            return "Team Net";
        case LeaderboardType.TeamGrossStableford:
            return "Team Gross Stableford";
        case LeaderboardType.TeamNetStableford:
            return "Team Net Stableford";
        case LeaderboardType.IndividualGross:
            return "Individual Gross";
        case LeaderboardType.IndividualNet:
            return "Individual Net";
        case LeaderboardType.IndividualGrossStableford:
            return "Individual Gross Stableford";
        case LeaderboardType.IndividualNetStableford:
            return "Individual Net Stableford";
    }
}