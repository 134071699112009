import React, {useState} from "react";
import {connect} from "react-redux";
import {TopTeam, TournamentApi} from "client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";

interface IProps {
	token?: string;
	dispatch?: any;
	index: number;
	team: TopTeam;
	onDone(): Promise<void>;
}

const TournamentDashboardTeamListItem: React.FC<IProps> = (props) => {

	const {index, team} = props;
	const [showPromptOpenScoresModal, setShowPromptOpenScoresModal] = useState(false);
	const [showPromptCloseScoresModal, setShowPromptCloseScoresModal] = useState(false);

	function promptOpenScores(e?): void {
		if (e) {
			e.preventDefault();
		}

		setShowPromptOpenScoresModal(!showPromptOpenScoresModal);
	}

	function promptConfirmClose(e?): void {
		if (e) {
			e.preventDefault();
		}

		setShowPromptCloseScoresModal(!showPromptCloseScoresModal);
	}

	async function confirmReOpen(): Promise<void> {
		props.dispatch(incrementLoading());
		setShowPromptOpenScoresModal(false);

		try {
			await new TournamentApi(getConfig(props.token)).reopenScoringForTeam({
				iDBody: {
					id: team.id,
				},
			});
			props.dispatch(decrementLoading());
			await props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}

	}

	async function confirmClose(): Promise<void> {
		props.dispatch(incrementLoading());
		setShowPromptCloseScoresModal(false);

		try {
			await new TournamentApi(getConfig(props.token)).closeScoringForTeam({
				iDBody: {
					id: team.id,
				},
			});
			props.dispatch(decrementLoading());
			await props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
			props.dispatch(decrementLoading());
		}

	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showPromptOpenScoresModal}
				fade={true}
				centered={true}
				toggle={promptOpenScores}
			>
				<ModalHeader>Re-Open Team <b>{team?.name?.label}</b> Scoring?</ModalHeader>

				<ModalBody>
					<p>
						Are you sure you want to re-open scoring for Team <b>{team?.name?.label}</b>? This will allow the members to once again enter scores through the score card page of the player portal until you or one of the players re-submits.
					</p>
				</ModalBody>

				<ModalFooter>
					<Button color="gray" onClick={promptOpenScores}>
						Cancel
					</Button>

					<Button color="lightBlue" onClick={confirmReOpen} className="ml-3">
						Confirm
					</Button>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={showPromptCloseScoresModal}
				fade={true}
				centered={true}
				toggle={promptConfirmClose}
			>
				<ModalHeader>Submit scoring for Team <b>{team?.name?.label}</b>?</ModalHeader>

				<ModalBody>
					<p>
						Are you sure you want to submit scoring for Team <b>{team?.name?.label}</b>? They will no longer be able to make edits unless you re-open scoring for them.
					</p>
				</ModalBody>

				<ModalFooter>
					<Button color="gray" onClick={promptConfirmClose}>
						Cancel
					</Button>

					<Button color="lightBlue" onClick={confirmClose} className="ml-3">
						Confirm
					</Button>
				</ModalFooter>
			</Modal>

			<div>
				<h5 className="d-inline">
					{`${index + 1}. ${team.name.label} - `}
				</h5>
				<p className="d-inline">{team.name.subtitle}</p>

				{team.isClosed ? (
					<a href="#" onClick={promptOpenScores} className="text-success ml-2">
						<span style={{fontSize: "0.9rem"}}>
							(Done Scoring) - click to re-open
						</span>
					</a>
				) : (
					<React.Fragment>
						{team.isFinished ? (
							<a href="#" onClick={promptConfirmClose} className="text-warning ml-2">
								<span style={{fontSize: "0.9rem"}}>
									(Finished scoring, not submitted) - click to submit
								</span>
							</a>
						) : (
							<span style={{fontSize: "0.9rem"}} className="text-lightBlue ml-2">
								(Still scoring)
							</span>
						)}
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(TournamentDashboardTeamListItem);
