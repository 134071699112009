import React, {ReactNode} from "react";
import {defaultHoleNumbers, GolfCourseButton, GolfCourseField} from "./GolfCourseInput";

interface ICustomGolfInputEntry {
	title: string,
	values: string[],
}

interface IProps {
	customEntries: Array<ICustomGolfInputEntry>;
	activeHoles: boolean[];
}

const GolfCourseScoresDisplay: React.FC<IProps> = (props: IProps) => {

	function makeColumns(index: number): ReactNode {
		return (
			<div className="d-flex flex-column" key={"hole-score_" + index}>
				<GolfCourseButton
					label={(index).toString()}
					selected={props.activeHoles[index]}
					onClick={undefined}
				/>

				{props.customEntries.map((entry: ICustomGolfInputEntry, j: number) => {
					return (
						<GolfCourseField
							value={entry.values[index - 1] as unknown as number}
							onChange={undefined}
							parTabIndex={0}
						/>
					);
				})}
			</div>
		);
	}

	return (
		<div className="d-flex flex-row">
			<div className="d-flex flex-column" style={{minWidth: 130, whiteSpace: "nowrap"}}>
				<div className="golf-course-field-wrapper"/>
				{props.customEntries.map((entry: ICustomGolfInputEntry, i: number) => {
					return (
						<div className="golf-course-label-wrapper">
							<p className="golf-course-label">{entry.title}</p>
						</div>
					);
				})}
			</div>
			{defaultHoleNumbers.map(makeColumns)}
		</div>
	);
};

export default GolfCourseScoresDisplay;
