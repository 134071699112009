import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useQuery} from "../utils/useQuery";
import {useHistory} from "react-router";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {TournamentApi, TournamentDetails, TournamentType} from "client";
import getConfig from "../utils/getConfig";


interface IProps {
    dispatch?: any;
    token?: string;
    tournamentID: string;
}

const TournamentPrintOuts: React.FC<IProps> = (props: IProps) => {

    const history = useHistory();
    const query = useQuery();
    const tournamentID: string = query.get("tournamentID");
    const [tournamentDetails, setTournamentDetails] = useState<TournamentDetails>(null);

    useEffect(() => {
        getTournamentDetails().then().catch();
    }, [tournamentID]);

    if (!tournamentID) {
        history.replace("/iscoregolfplus");
        return null;
    }

    async function getTournamentDetails(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            const res = await new TournamentApi(getConfig(props.token)).getTournamentDetails({tournamentID});
            setTournamentDetails(res);
        } catch (e) {
            props.dispatch(addError(e));
        }

        props.dispatch(decrementLoading());
    }

    if (!tournamentDetails) {
        return null;
    }

    return (
        <Container className="my-5">

            <h1>Print Outs</h1>
            <p>
                Click on a button below to see the generated document. From there, you can print or download the file.
            </p>

            <Card>
                <CardBody>
                    <div className="d-flex flex-column align-items-center">
                        <div className="my-3">
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_pairings_print_out?tournamentID=${tournamentID}`}
                               target="_blank" rel="noreferrer noopener">
                                <Button color="lightBlue">
                                    Pairing Report
                                </Button>
                            </a>
                        </div>

                        <div className="my-3">
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_players_alpha_print_out?tournamentID=${tournamentID}`}
                               target="_blank" rel="noreferrer noopener">
                                <Button color="lightBlue">
                                    Alpha Report
                                </Button>
                            </a>
                        </div>

                        <div className="my-3">
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_cartsigns_print_out?tournamentID=${tournamentID}&qr=true`}
                               target="_blank" rel="noreferrer noopener">
                                <Button color="lightBlue">
                                    Cart Signs +QR
                                </Button>
                            </a>
                        </div>

                        <div className="my-3">
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_cartsigns_print_out?tournamentID=${tournamentID}&qr=false`}
                               target="_blank" rel="noreferrer noopener">
                                <Button color="lightBlue">
                                    Cart Signs
                                </Button>
                            </a>
                        </div>

                        {tournamentDetails?.tournament?.primaryGame?.useHandicap ? (
                            tournamentDetails?.tournament?.primaryGame?.tournamentType === TournamentType.BESTBALLSTROKEPLAY ? (
                                <React.Fragment>
                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Team Results (Gross)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=true`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Team Results (Net)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_individual_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Individual Results (Gross)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_individual_results_print_out?tournamentID=${tournamentID}&handicap=true`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Individual Results (Net)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards (Gross)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=true`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards (Net)
                                            </Button>
                                        </a>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Results (Gross)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=true`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Results (Net)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards (Gross)
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=true`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards (Net)
                                            </Button>
                                        </a>
                                    </div>
                                </React.Fragment>
                            )
                        ) : (
                            tournamentDetails?.tournament?.primaryGame?.tournamentType === TournamentType.BESTBALLSTROKEPLAY ? (
                                <React.Fragment>
                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Team Results
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_individual_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Individual Results
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards
                                            </Button>
                                        </a>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_final_results_print_out?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Results
                                            </Button>
                                        </a>
                                    </div>

                                    <div className="my-3">
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}/tournament/get_scorecard_print_outs?tournamentID=${tournamentID}&handicap=false`}
                                           target="_blank" rel="noreferrer noopener">
                                            <Button color="lightBlue">
                                                Score Cards
                                            </Button>
                                        </a>
                                    </div>
                                </React.Fragment>
                            )
                        )}
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

export default connect((store: IStore, props: IProps) => {
    return {
        token: store.metaStore.token,
        ...props,
    }
})(TournamentPrintOuts);
