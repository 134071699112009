import React from "react";
import {TournamentApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	tournamentId: string;
	onClose(): void;
	onDone(): Promise<void>;
}

const SMSURLsToAllPlayersConfirmModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, tournamentId} = props;

	async function smsURLs(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(token)).sendURLsToAllPlayers({
				sendUrlsBody: {
					tournamentId,
					useSms: true,
					useEmail: false,
				},
			});

			await props.onDone();
			props.onClose();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
		>
			<ModalHeader>Confirm SMSing the tournament link to all players</ModalHeader>

			<ModalBody>
				<p>
					This will SMS every player their tournament link if they have it entered.
				</p>
			</ModalBody>

			<ModalFooter>
				<Button color="gray" onClick={props.onClose}>
					Cancel
				</Button>

				<Button color="darkBlue" onClick={smsURLs}>
					Confirm, SMS URLs
				</Button>
			</ModalFooter>
		</Modal>
	);

};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SMSURLsToAllPlayersConfirmModal);
